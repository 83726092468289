import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const AdminPolsek = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [polsek, setPolsek] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedPolsekId, setSelectedPolsekId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const itemsPerPage = 20;

    const fetchPolsek = useCallback(async (page = 1, search = '') => {
        try {
            const endpoint = search
                ? `/api/search_polsek/${search}?page=${page}`
                : `/api/list_polsek?page=${page}`;

            const url = `${baseURL}${endpoint}`;
            console.log(`Fetching polsek from: ${url}`);

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const { data, links, current_page, last_page } = response.data.polsek;
            setPolsek(data);
            setPaginationLinks(links);
            setCurrentPage(current_page);
            setTotalPages(last_page);
            console.log('Polsek data fetched successfully');
        } catch (error) {
            console.error('There was an error fetching the polsek data!', error);
        }
    }, [baseURL, token]);

    useEffect(() => {
        fetchPolsek(currentPage, searchKeyword);
    }, [fetchPolsek, searchKeyword, currentPage]);

    const deletePolsek = useCallback((polsekId) => {
        axios.delete(`${baseURL}/api/delete_polsek/${polsekId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(() => {
            fetchPolsek(currentPage, searchKeyword); // Refresh data after deletion
        })
        .catch(error => {
            console.error('There was an error deleting the polsek!', error);
        });
    }, [token, baseURL, currentPage, fetchPolsek, searchKeyword]);

    const handleEditClick = (polsekId) => {
        navigate(`/admin/editpolsek/${polsekId}`);
    };

    const handleDeleteClick = (polsekId) => {
        setSelectedPolsekId(polsekId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deletePolsek(selectedPolsekId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Manajemen Polsek</div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-row w-full justify-between">
                            <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
                            <Link to="/admin/addpolsek" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4" />
                                <div className="hidden laptop:flex">Input Polsek</div>
                            </Link>
                        </div>
                        <PolsekTable 
                            polsek={polsek} 
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            handleEditClick={handleEditClick} 
                            handleDeleteClick={handleDeleteClick} 
                        />
                        {renderPagination()}
                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

const SearchBar = ({ searchKeyword, setSearchKeyword }) => (
    <div className="flex w-1/2 pt-4 pb-2 bg-white text-black-light">
        <div className="flex flex-col relative mt-1 gap-4">
            <input 
                type="text" 
                id="search" 
                placeholder="Search..." 
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="block w-full rounded-md border-0 px-2 py-1 mr-20 shadow-sm ring-1 ring-black-light placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-black-light sm:text-sm sm:leading-6" 
            />
        </div>
    </div>
);

const PolsekTable = ({ polsek, currentPage, itemsPerPage, handleEditClick, handleDeleteClick }) => {
    // Calculate the starting index based on the current page and items per page
    const startingIndex = (currentPage - 1) * itemsPerPage + 1;

    return (
        <div className="mt-6 text-base">
            <div className="min-w-full overflow-x-auto">
                <div className="overflow-y-auto">
                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                        <thead className="bg-gray-light">
                            <tr>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLSEK</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLRES</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLDA</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JABATAN</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NAMA</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">KONTAK</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-dark">
                        {polsek.length > 0 ? (
                            polsek.map((polsek, index) => (
                                <tr key={polsek.id}>
                                    {/* Display the index number, adjusted to continue from the last page */}
                                    <td className="px-5 py-2 whitespace-normal">{startingIndex + index}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polsek.polsek}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polsek.polres}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polsek.polda}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polsek.jabatan}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polsek.nama}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polsek.kontak}</td>
                                    <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                        <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto" onClick={() => handleEditClick(polsek.id)}>
                                            <FontAwesomeIcon icon={faEdit} className="text-white h-4 w-4" />
                                        </button>
                                        <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto" onClick={() => handleDeleteClick(polsek.id)}>
                                            <FontAwesomeIcon icon={faTrash} className="text-white h-4 w-4" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No data available.</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
            <div className="flex flex-row justify-between">
                <button
                    onClick={handleConfirmDelete}
                    className="bg-green text-white px-4 py-2 rounded-lg"
                >
                    Ya
                </button>
                <button
                    onClick={handleCancelDelete}
                    className="bg-red text-white px-4 py-2 rounded-lg"
                >
                    Tidak
                </button>
            </div>
        </div>
    </div>
);

export default AdminPolsek;
