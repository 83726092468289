import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from '../../api/axios'; // Adjust the import path based on your project structure
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const LaporanDetail = () => {
    const { id } = useParams();
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;

    const [laporan, setLaporan] = useState(null);
    const [images, setImages] = useState([]);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const fetchLaporanDetail = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/laporan/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setLaporan(response.data.laporan);
                setImages(response.data.image);
                setComments(response.data.komen);
            } catch (error) {
                console.error('Error fetching laporan detail:', error);
            }
        };

        fetchLaporanDetail();
    }, [baseURL, id, token]);

    if (!laporan) {
        return (
            <div className="flex items-center">
                <div className="mt-8">
                    <div className="flex flex-col laptop:grid laptop:grid-cols-2 gap-4">
                        <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="">
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-col gap-10 text-lg mt-6 laptop:layout">
                            <div className="flex flex-row text-sm gap-3 items-center laptop:text-base">
                                <div>
                                    <span to="/polda/aceh">{String(laporan.created_by)}</span>
                                </div>
                                <div><FontAwesomeIcon icon={faChevronRight} /></div>
                                <div>LAPORAN {String(laporan.kategori)}</div>
                                <div><FontAwesomeIcon icon={faChevronRight} /></div>
                                <div>{String(laporan.jenisLaporan)}</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex text-xl laptop:text-2xl font-mp-bold">
                                    {laporan.judulLaporan?.toUpperCase()}
                                </div>
                                <div className="flex flex-col text-base">
                                    <div className="flex">Dibuat oleh: {laporan.created_by?.toUpperCase()}</div>
                                    <div className="flex">Pada: {laporan.tglLaporan}</div>
                                </div>
                            </div>

                            <div className="flex items-center object-contain justify-center mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
                                <Carousel
                                    showArrows={true}
                                    showIndicators={true}
                                    infiniteLoop={false}
                                    dynamicHeight={false}
                                    autoPlay={false}
                                    showStatus={false}
                                    showThumbs={false}
                                >
                                    {images.length > 0 ? (
                                        images.map((image, index) => (
                                            <div key={index}>
                                                <div className="flex relative bg-cover">
                                                    <img
                                                        src={baseURL + image.image}
                                                        alt=""
                                                        width={0}
                                                        height={0}
                                                        sizes="100vw"
                                                        className="laptop:w-full aspect-[16/9] rounded-xl object-cover"
                                                        loading="lazy"
                                                        style={{ pointerEvents: 'auto' }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div>
                                            <div className="flex relative bg-cover">
                                                <img
                                                    src={baseURL + laporan.thumbnail}
                                                    alt="Thumbnail"
                                                    width={0}
                                                    height={0}
                                                    sizes="100vw"
                                                    className="w-full aspect-[16/9] rounded-xl object-cover"
                                                    loading="lazy"
                                                    style={{ pointerEvents: 'auto' }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Carousel>
                            </div>

                            <div className="flex flex-col gap-8 mt-10 text-lg text-justify">
                                {laporan.waktu && (
                                    <div className="flex flex-col">
                                        <div className="flex font-mp-bold">Waktu Kegiatan:</div>
                                        <div className="mt-4">{new Date(laporan.waktu).toLocaleDateString('en-GB')}</div>
                                    </div>
                                )}

                                {laporan.konten && (
                                    <div className="flex flex-col">
                                        <div className="font-mp-bold">Laporan Kegiatan</div>
                                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: laporan.konten }}></div>
                                    </div>
                                )}

                                {laporan.hasil && (
                                    <div className="flex flex-col text-lg">
                                        <div className="font-mp-bold">Hasil Kegiatan</div>
                                        <div className="mt-4">{laporan.hasil}</div>
                                    </div>
                                )}

                                {laporan.saran && (
                                    <div className="flex flex-col text-lg">
                                        <div className="font-mp-bold">Saran Rekomendasi</div>
                                        <div className="mt-4">{laporan.saran}</div>
                                    </div>
                                )}
                            </div>

                            <CommentsSection
                                komen={comments}
                                laporan_id={laporan.id}
                                dari={String(auth.user.instansi)}
                                untuk={String(laporan.created_by)}
                                token={token}
                                setComments={setComments}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CommentsSection = ({ komen = [], laporan_id, dari, untuk, token, setComments }) => {
    const baseURL = useContext(ApiContext);
    const [localComments, setLocalComments] = useState(komen); // Use local state for comments
    const [newComment, setNewComment] = useState('');
    const currentUser = dari; // Assuming 'dari' is the current user identifier

    useEffect(() => {
        setLocalComments(komen); // Sync local comments with props
    }, [komen]);

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handlePostComment = async (e) => {
        e.preventDefault();

        if (newComment.trim() === '') {
            alert('Komentar tidak boleh kosong');
            return;
        }

        const formData = {
            id: '',
            laporan_id,
            dari: currentUser, // Use current user identifier for 'dari'
            untuk,
            created_at: new Date().toISOString(),
            komen: newComment
        };

        const apiEndpoint = `${baseURL}/api/store_komen`; // Replace with your actual endpoint

        try {
            const response = await axios.post(apiEndpoint, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Comment added successfully:', response.data);
            setNewComment('');
            setLocalComments(prevComments => [...prevComments, response.data]); // Update comments list locally
            setComments(prevComments => [...prevComments, response.data]); // Update comments list in LaporanDetail
            window.location.reload();
        } catch (error) {
            console.error('Error adding comment:', error.response ? error.response.data : error.message);
        }
    };

    const handleDeleteComment = async (commentId) => {
        const apiEndpoint = `${baseURL}/api/delete_komen/${commentId}`; // Replace with your actual endpoint

        try {
            await axios.delete(apiEndpoint, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Comment deleted successfully');
            setLocalComments(prevComments => prevComments.filter(comment => comment.id !== commentId)); // Remove comment locally
            setComments(prevComments => prevComments.filter(comment => comment.id !== commentId)); // Remove comment from LaporanDetail
        } catch (error) {
            console.error('Error deleting comment:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div>
            <div className="flex flex-col gap-3">
                <label className="text-lg font-mp-bold mb-2 py-4 border-b border-gray-dark">Komentar</label>
                {localComments.length > 0 ? localComments.map((comment, index) => (
                    <div key={index} className="py-3 flex flex-row items-center">
                        <div className="flex justify-center items-center rounded-full bg-blue p-2 w-auto">
                            <img
                                src={baseURL + comment.logo} // Use the logo URL from the comment data
                                alt="User Logo"
                                className="h-5 w-5 rounded-full"
                            />
                        </div>
                        <div className="ml-3 flex flex-col justify-start gap-1">
                            <div className="flex items-center gap-4">
                                <span className="font-bold">{String(comment.dari)}</span>
                                <span className="text-base ml-2"> {String(comment.created_at)}</span>
                                {comment.dari === currentUser && (
                                    <FontAwesomeIcon 
                                        icon={faTrash} 
                                        className="text-red h-4 w-4 cursor-pointer"
                                        onClick={() => handleDeleteComment(comment.id)} // Add delete functionality
                                    />
                                )}
                            </div>
                            <span className="text-lg whitespace-pre-wrap">{String(comment.komen)}</span>
                        </div>
                    </div>
                )) : <div className="py-3">Belum ada komentar</div>}
                <textarea
                    type="text"
                    id="komentar"
                    placeholder="Tambahkan komentar..."
                    className="block w-full h-20 rounded-md border-0 mt-2 px-3 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                    value={newComment}
                    onChange={handleCommentChange}
                ></textarea>
                <div className="flex justify-end">
                    <button
                        className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg"
                        onClick={handlePostComment}
                    >
                        Post
                    </button>
                </div>
            </div>
            
        </div>
    );
};

export default LaporanDetail;
