import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const TpiPelabuhan = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [pelabuhan, setPelabuhan] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedPelabuhanId, setSelectedPelabuhanId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const itemsPerPage = 20; // Adjust as needed

    const fetchPelabuhan = useCallback((page) => {
        axios.get(`${baseURL}/api/pelabuhan?page=${page}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            const { data, links, last_page, current_page } = response.data.pelabuhan;
            setPelabuhan(data);
            setPaginationLinks(links);
            setCurrentPage(current_page);
            setTotalPages(last_page);
        })
        .catch(error => {
            console.error('There was an error fetching the pelabuhan data!', error);
        });
    }, [token, baseURL]);

    useEffect(() => {
        fetchPelabuhan(currentPage);
    }, [currentPage, fetchPelabuhan]);

    const deletePelabuhan = useCallback((pelabuhanId) => {
        axios.delete(`${baseURL}/api/delete_tpi/${pelabuhanId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(() => {
            fetchPelabuhan(currentPage); // Refresh data after deletion
        })
        .catch(error => {
            console.error('There was an error deleting the pelabuhan!', error);
        });
    }, [token, baseURL, currentPage, fetchPelabuhan]);

    const handleEditClick = (pelabuhanId) => {
        navigate(`/tpi/editpelabuhan/${pelabuhanId}`);
    };

    const handleDeleteClick = (pelabuhanId) => {
        setSelectedPelabuhanId(pelabuhanId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deletePelabuhan(selectedPelabuhanId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2">
            {/* Previous Button */}
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}

            {/* Page Number Buttons */}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}

            {/* Next Button */}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Pelabuhan Laut Internasional</div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-row w-full justify-between">
                        {(auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1) ||
                            (auth?.user?.instansi === pelabuhan.instansi) ? (
                                <>
                            <Link to="/tpi/addpelabuhan" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4" />
                                <div>Input Pelabuhan</div>
                            </Link>
                            </>
                            ) : null}
                        </div>
                        <PelabuhanTable
                            auth={auth} 
                            pelabuhan={pelabuhan} 
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            handleEditClick={handleEditClick} 
                            handleDeleteClick={handleDeleteClick} 
                        />
                        {renderPagination()}
                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

const PelabuhanTable = ({auth, pelabuhan, currentPage, itemsPerPage, handleEditClick, handleDeleteClick }) => {
    const startingIndex = (currentPage - 1) * itemsPerPage + 1;

    return (
        <div className="mt-6 text-base">
            <div className="min-w-full overflow-x-auto">
                <div className="overflow-y-auto">
                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                        <thead className="bg-gray-light">
                            <tr>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">PROVINSI</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">TPI</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">WILKUM</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NAMA</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">KONTAK</th>
                                {
                                    auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                        <>        
                                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                        </>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-dark">
                            {pelabuhan.map((item, index) => (
                                <tr key={item.id}>
                                    <td className="px-5 py-2 whitespace-normal">{startingIndex + index}</td>
                                    <td className="px-5 py-2 whitespace-normal">{item.provinsi}</td>
                                    <td className="px-5 py-2 whitespace-normal">{item.tpi}</td>
                                    <td className="px-5 py-2 whitespace-normal">{item.wilkum}</td>
                                    <td className="px-5 py-2 whitespace-normal">{item.nama}</td>
                                    <td className="px-5 py-2 whitespace-normal">{item.kontak}</td>
                                    <td className="px-5 py-2 whitespace-normal flex flex-row gap-3 items-center">
                                    {
                                        auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                            <> 
                                            <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto" onClick={() => handleEditClick(item.id)}>
                                                <FontAwesomeIcon icon={faEdit} className="text-white" />
                                            </button>
                                            <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto" onClick={() => handleDeleteClick(item.id)}>
                                                <FontAwesomeIcon icon={faTrash} className="text-white" />
                                            </button>
                                            </>
                                        ) : null
                                    }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
    <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white border border-gray-dark rounded-lg shadow-lg p-6 w-80">
            <p className="text-center">Apakah Anda ingin menghapus data ini?</p>
            <div className="flex justify-center gap-4 mt-4">
                <button onClick={handleConfirmDelete} className="bg-red-500 text-white px-4 py-2 rounded-lg">Ya</button>
                <button onClick={handleCancelDelete} className="bg-gray-500 text-white px-4 py-2 rounded-lg">Tidak</button>
            </div>
        </div>
    </div>
);

export default TpiPelabuhan;
