import React from 'react'
import AuthImage from './AuthImage'

const ForgotPassword = () => {
    return (
        <div className="flex flex-row w-full h-screen text-start font-mp">
            <AuthImage />
            
            <div className="bg-white text-light-black w-4/12 py-24 px-7">
                <div className="flex flex-col h-full justify-between ">
                    <div className="flex flex-col">
                        <div className="mt-4 text-lg">Silahkan masukkan alamat email untuk mengirim link pergantian password</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-col">
                            <label
                                htmlFor="email"
                                className="block text-sm leading-6 text-black"
                            >
                                Email
                            </label>
                            <div className="mt-2">
                                <input
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                    <a href="/login" className="bg-blue hover:bg-green text-[white] flex justify-center items-center h-12 rounded-lg">Send Email</a>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword