import React, { useContext, useEffect, useState } from 'react'
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';

const KemenForm = () => {
    const baseURL = useContext(ApiContext);
    const [formData, setFormData] = useState({
        kementrian: '',
        nama: '',
        jabatan: '',
        kontak: ''
    });
    
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);
    
    useEffect(() => {
        if (isEditMode) {
            const fetchKementrianData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/kementrian/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
                });
                const kementrian = response.data.kementrian;
                setFormData({
                    kementrian: kementrian.kementrian || '',
                    nama: kementrian.nama || '',
                    jabatan: kementrian.jabatan || '',
                    kontak: kementrian.kontak || '',
                });
            } catch (error) {
                console.error('Error fetching kementrian data:', error);
            }
            };

            fetchKementrianData();
            }
    }, [id, isEditMode, token, baseURL]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEditMode ? `${baseURL}/api/update_kementrian/${id}` : `${baseURL}/api/store_kementrian`;

        try {
            const response = await axios.post(apiEndpoint, formData, {
            headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('kementrian saved successfully:', response.data);
            navigate('/kemenlem');
        } catch (error) {
            console.error('Error saving kementrian:', error.response ? error.response.data : error.message);
        }
    };
    
    const handleCancel = () => {
        navigate('/kemenlem');
    };

    return (
        <div className="mt-8">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="text-lg font-bold">{isEditMode ? 'Edit Kementerian' : 'Input Kementerian'}</div>
                        <div className="flex flex-col gap-6 px-4">
                            <FormInput label="Kementerian" type="text" name="kementrian" value={formData.kementrian} handleChange={handleChange} />
                            <FormInput label="Nama" type="text" name="nama" value={formData.nama} handleChange={handleChange} />
                            <FormInput label="Jabatan" type="text" name="jabatan" value={formData.jabatan} handleChange={handleChange} />
                            <FormInput label="Kontak" type="text" name="kontak" value={formData.kontak} handleChange={handleChange} />
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Cancel
                            </button>
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const FormInput = ({ label, type, name, value, handleChange }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <input
                type={type}
                name={name}
                id={name}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                value={value}
                onChange={handleChange}
            />
        </div>
    </div>
);

export default KemenForm
