import React, { useState, useEffect } from 'react';

const AuthImage = () => {
    const [isVisible, setIsVisible] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsVisible(window.innerWidth >= 1024);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!isVisible) return null; // Hide component if not visible

    return (
        <div className="relative overflow-hidden laptop:w-8/12 border-r-8 border-red">
            <div className="absolute inset-0 bg-cover bg-no-repeat" loading="lazy" style={{ backgroundImage: "url('/images/login.png')" }}></div>
            <div className="absolute inset-0 h-full w-full bg-blue bg-opacity-[.85]"></div>
            <div className="flex flex-col justify-center items-center text-center relative z-10">
                <img
                    src="/images/imbas.png"
                    alt=""
                    width={0}
                    height={0}
                    sizes="100vw"
                    loading="lazy"
                    className="flex items-center w-32"
                />
                <div className="flex font-mp-bold text-2xl text-red">IMBAS</div>
                <div className="flex text-xl text-white">Informasi Manajemen Perbatasan</div>
            </div>
        </div>
    );
};

export default AuthImage;
