import { useContext, useDebugValue, useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import {jwtDecode} from 'jwt-decode'; 

const useAuth = () => {
    const { auth, setAuth } = useContext(AuthContext);

    useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out");

    useEffect(() => {
        const storedAuth = localStorage.getItem('auth');
        if (storedAuth) {
            const parsedAuth = JSON.parse(storedAuth);
            const token = parsedAuth.accessToken;
            if (token && !isTokenExpired(token)) {
                setAuth(parsedAuth);
            } else {
                // Clear expired token
                localStorage.removeItem('auth');
                setAuth({});
            }
        }
    }, [setAuth]);

    const isTokenExpired = (token) => {
        try {
            const decoded = jwtDecode(token);
            const exp = decoded.exp * 1000; // Convert to milliseconds
            return exp < Date.now();
        } catch (error) {
            console.error("Token decoding failed:", error);
            return true;
        }
    };

    return { auth, setAuth };
}

export default useAuth;




