import React, { useContext, useEffect, useState } from 'react';
import Tiles from '../../components/reusable/Tiles';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const Polda = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [polda, setPolda] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/dropdown_polda`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
    
                const processedData = response.data.polda
                    .filter(item => item.polda.startsWith('POLDA'))
                    .map(item => {
                        // Remove "POLDA" from the displayed name while keeping it in the URL
                        const cleanPoldaName = item.polda.replace(/^POLDA\s*/, '');
                        return {
                            ...item,
                            displayName: cleanPoldaName, // Use this for displaying without "POLDA"
                            url: `/polda/${item.polda}`, // Use the original name with "POLDA" for the URL
                        };
                    });
    
                setPolda(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [token, baseURL]);

    const handleTileClick = (item) => {
        navigate(item.url, { state: { poldaData: item.polda } });
    };

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Polda Perbatasan</div>
            <div className="mt-8 font-mp-bold">
                <div className="grid grid-cols-2 gap-4 laptop:grid laptop:grid-cols-5">
                    {polda.map((item, id) => (
                        <div
                            key={id}
                            className="cursor-pointer" // Add pointer cursor for tiles
                            onClick={() => handleTileClick(item)} // Handle navigation with state
                        >
                            <Tiles>
                                <div className="flex items-center justify-center">
                                    <img
                                        src={baseURL + item.logo}
                                        alt={item.polda}
                                        width={0}
                                        height={0}
                                        sizes="100vw"
                                        className="h-3/12 w-3/12 object-contain"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="flex mt-4">{item.displayName}</div> {/* Display the cleaned name */}
                            </Tiles>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Polda;
