import React from 'react';

const Panduan = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/files/Guidebook IMBASCEPAT.pdf`; // Adjust the path if you store the file elsewhere

    return (
        <div className="h-full">
            {/* PDF Preview */}
            <iframe 
                src={pdfUrl} 
                title="PDF Preview" 
                className="w-full h-screen" // Adjust the height as per your needs
                style={{ minHeight: '500px' }} // Ensures a minimum height for smaller screens
            >
                Your browser does not support PDFs. <a href={pdfUrl}>Download the PDF</a>.
            </iframe>
        </div>
    );
}

export default Panduan;
