import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

const PoldaDetail = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const location = useLocation();

    // Access the passed data
    const { poldaData } = location.state || {};

    // New constant for modified poldaData name
    const cleanedPoldaName = poldaData ? poldaData.replace(/^POLDA\s*/, '') : '';

    const [logo, setLogo] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [isPolres, setIsPolres] = useState(true);
    const [chartData, setChartData] = useState({
        laporanBiasa: [],
        laporanKhusus: [],
        laporanTotal: 0,
        biasaTotal: 0,
        khususTotal: 0,
    });
    const [polresData, setPolresData] = useState([]);
    const [polsekData, setPolsekData] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`${baseURL}/api/polda_details/${poldaData}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            // Assuming poldaData corresponds to a specific polda
            const poldaDetail = response.data.polda?.find(item => item.polda === poldaData) || {};

            setPolresData(response.data.polres);
            setPolsekData(response.data.polsek);
            setLogo(poldaDetail.logo || ""); // Set the logo URL
            setChartData({
                laporanBiasa: response.data.chart_biasa || [],
                laporanKhusus: response.data.chart_khusus || [],
                laporanTotal: response.data.laporan_total || 0,
                biasaTotal: response.data.laporan_biasa || 0,
                khususTotal: response.data.laporan_khusus || 0,
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [baseURL, token, poldaData]); // CleanedPoldaName is derived from poldaData

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleEditClick = (id, type) => {
        if (type === 'polres') {
            navigate(`/admin/editpolres/${id}`);
        } else if (type === 'polsek') {
            navigate(`/admin/editpolsek/${id}`);
        }
    };
    
    const handleDeleteClick = (id, type) => {
        setSelectedItemId(id);
        setIsPolres(type === 'polres');
        setShowModal(true);
    };

    const handleViewLaporan = (polresName) => {
        navigate(`/polda/${poldaData}/${polresName}`, { state: { poldaData: poldaData } });
    };

    const handleConfirmDelete = async () => {
        try {
            if (isPolres) {
                await axios.delete(`${baseURL}/api/delete_polres/${selectedItemId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            } else {
                await axios.delete(`${baseURL}/api/delete_polsek/${selectedItemId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            }
            fetchData(); // Refresh data after deletion
        } catch (error) {
            console.error('Error deleting item:', error);
        }
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };
    

    //const currentPolda = polda.find(item => item.link === `/polda/${poldaName}`) || { name: '', fullname:'' };

    return (
        <div className="">
            <div className="font-mp-bold text-xl text-gray-light">{poldaData}</div>
            <div className="mt-10">
                <div className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                        <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                            <div className="text-lg font-mp-bold justify-start">
                                Laporan
                            </div>
                            <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Biasa</div>
                                    <LaporanBiasa
                                        laporanBiasa={chartData.laporanBiasa}
                                        laporanBiasaTotal={chartData.biasaTotal}
                                    />
                                </div>
                                <div className="flex flex-col gap-10 h-full justify-between items-center text-center font-mp-bold">
                                    <div className="flex items-center text-start justify-center">
                                        <img
                                            src={baseURL + logo}
                                            alt="logo"
                                            width={0}
                                            height={0}
                                            sizes="100vw"
                                            className="flex h-full w-full aspect-[16/9] rounded-xl object-contain"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                        <div className="text-xl">{chartData.laporanTotal}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Khusus</div>
                                    <LaporanKhusus
                                        laporanKhusus={chartData.laporanKhusus}
                                        laporanKhususTotal={chartData.khususTotal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">POLRES {cleanedPoldaName}</div>
                        </div>
                        <div className="mt-6 text-sm">
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLRES</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JABATAN</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NAMA</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">KONTAK</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">LAPORAN</th>
                                                {
                                                    auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                        <>
                                                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                        </>
                                                    ) : null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
                                            {polresData.map((polres, index) => (
                                                <tr key={polres.id}>
                                                    <td className="px-5 py-2 whitespace-normal">{index + 1}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polres.polres}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polres.jabatan}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polres.nama}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polres.kontak}</td>
                                                    <td className="px-5 py-2 whitespace-normal">
                                                        <button className="flex w-full justify-center items-center rounded-lg bg-blue p-2 w-auto" onClick={() => handleViewLaporan(polres.polres)}>
                                                            <FontAwesomeIcon icon={faFileLines} className="text-white h-4 w-4" />
                                                        </button>
                                                    </td>
                                                    {
                                                        auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                            <>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                                <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleEditClick(polres.id, 'polres')}
                                                                    />
                                                                </button>
                                                                <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleDeleteClick(polres.id, 'polres')}
                                                                    />
                                                                </button>
                                                            </td>
                                                            </>
                                                        ) : null
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">POLSEK {cleanedPoldaName}</div>
                        </div>
                        <div className="mt-6 text-sm">
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLSEK</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLRES</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JABATAN</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NAMA</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">KONTAK</th>
                                                {
                                                    auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                        <>
                                                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                        </>
                                                    ) : null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
                                            {polsekData.map((polsek, index) => (
                                                <tr key={polsek.id}>
                                                    <td className="px-5 py-2 whitespace-normal">{index + 1}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polsek.polsek}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polsek.polres}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polsek.jabatan}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polsek.nama}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{polsek.kontak}</td>
                                                    {
                                                        auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                            <>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                                <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleEditClick(polsek.id, 'polsek')}
                                                                    />
                                                                </button>
                                                                <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleDeleteClick(polsek.id, 'polsek')}
                                                                    />
                                                                </button>
                                                            </td>
                                                            </>
                                                        ) : null
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <DeleteConfirmationModal
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
};

const DeleteConfirmationModal = ({ onConfirm, onCancel }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
            <div className="flex flex-row justify-between">
                <button
                    onClick={onConfirm}
                    className="bg-green text-white px-4 py-2 rounded-lg"
                >
                    Ya
                </button>
                <button
                    onClick={onCancel}
                    className="bg-red text-white px-4 py-2 rounded-lg"
                >
                    Tidak
                </button>
            </div>
        </div>
    </div>
);

const LaporanBiasa = ({ laporanBiasa, laporanBiasaTotal }) => {
    const defaultData = {
        labels: ['Patroli', 'Pengamanan', 'Sosialisasi', 'Sinergitas'],
        series: [0, 0, 0, 0],
    };

    const mapLaporanBiasa = (data) => ({
        'Patroli': data.patroli || 0,
        'Pengamanan': data.pengamanan || 0,
        'Sosialisasi': data.sosialisasi || 0,
        'Sinergitas': data.sinergitas || 0,
    });

    const mappedData = laporanBiasa ? mapLaporanBiasa(laporanBiasa) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBiasaChart laporanBiasa={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBiasaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBiasaChart = ({ laporanBiasa }) => {
    const { labels = [], series = [] } = laporanBiasa || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanKhusus = ({ laporanKhusus, laporanKhususTotal }) => {
    const defaultData = {
        labels: ['Penyelundupan Orang', 'Penyelundupan Sembako', 'Penyelundupan Satwa', 'Penyelundupan Hasil Bumi',
            'Penyelundupan Senjata/Bahan Peledak', 'Narkoba', 'Pencurian Ikan/Illegal Fishing', 'Pelintas Batas Ilegal', 'Terorisme', 'Lainnya'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };

    const mapLaporanKhusus = (data) => ({
        'Penyelundupan Orang': data.orang || 0,
        'Penyelundupan Sembako': data.sembako || 0,
        'Penyelundupan Satwa': data.satwa || 0,
        'Penyelundupan Hasil Bumi': data.bumi || 0,
        'Penyelundupan Senjata/Bahan Peledak': data.senjata || 0,
        'Narkoba': data.narkoba || 0,
        'Pencurian Ikan/Illegal Fishing': data.ikan || 0,
        'Pelintas Batas Ilegal': data.batas || 0,
        'Terorisme': data.terorisme || 0,
        'Lainnya': data.lainnya || 0,
    });

    const mappedData = laporanKhusus ? mapLaporanKhusus(laporanKhusus) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanKhususChart laporanKhusus={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanKhususTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanKhususChart = ({ laporanKhusus }) => {
    const { labels = [], series = [] } = laporanKhusus || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6', '#F97316', '#14B8A6', '#6B7280', '#6366F1', '#EC4899'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

export default PoldaDetail;