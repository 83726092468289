import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../context/apiConfig';
import AuthContext from '../context/AuthProvider';
import axios from '../api/axios';
import Card from '../components/reusable/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileBeam, faFrown, faMeh, faSmile, faGrin, faHourglass, faHourglass1, faHourglass2, faHourglass3, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [chartData, setChartData] = useState({
        laporanBiasa: [],
        laporanKhusus: [],
        laporanTotal: 0,
        biasaTotal: 0,
        khususTotal: 0,
    });
    const [performaData, setPerformaData] = useState({
        rating: '',
        state: ''
    });
    const [laporanData, setLaporanData] = useState([]);
    const [leaderboardData, setLeaderboardData] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const [
                chartHomeResponse, 
                performaHomeResponse, 
                laporanHomeResponse, 
                leaderboardResponse
            ] = await Promise.all([
                axios.get(`${baseURL}/api/chart_home`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${baseURL}/api/performance`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${baseURL}/api/laporan_home`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                axios.get(`${baseURL}/api/leaderboard`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            ]);
    
            setChartData({
                laporanBiasa: chartHomeResponse.data.laporan_biasa || [],
                laporanKhusus: chartHomeResponse.data.laporan_khusus || [],
                laporanTotal: chartHomeResponse.data.total_laporan || 0,
                biasaTotal: chartHomeResponse.data.kategori_laporan?.biasa || 0,
                khususTotal: chartHomeResponse.data.kategori_laporan?.khusus || 0,
            });
            setPerformaData(performaHomeResponse.data || {});
            setLaporanData(laporanHomeResponse.data.laporan || []);
            setLeaderboardData(leaderboardResponse.data.leaderboard || []);
    
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    }, [baseURL, token]);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading) {
        return (
            <section className="h-auto">
                <div className="font-mp-bold text-xl text-gray-light">Home</div>
                <div className="mt-8">
                    <div className="flex gap-8 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                        <div className="flex flex-col p-5 h-auto w-full text-base bg-white border border-gray-dark rounded-lg shadow-lg shadow-gray-box col-span-3">
                            <div className="text-center items-center">Loading....</div>
                        </div>
                        <Card>
                            <div className="text-center items-center">Loading....</div>
                        </Card>
                    </div>
                    <div className="flex flex-col gap-8 mt-6 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                        <Card className="col-span-2">
                            <div className="text-center items-center">Loading....</div>
                        </Card>
                        <Card className="col-span-2">
                            <div className="text-center items-center">Loading....</div>
                        </Card>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Home</div>
            <div className="mt-8">
                <div className="flex flex-col gap-8 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                    <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark rounded-lg shadow-lg shadow-gray-box col-span-3">
                        <div className="flex flex-col w-full px-8 py-6 justify-center items-center laptop:flex-row laptop:justify-evenly">
                            <div className="flex flex-col w-full items-center justify-center text-base">
                                <div className="flex font-mp-bold">Laporan Biasa</div>
                                <LaporanBiasa
                                    laporanBiasa={chartData.laporanBiasa}
                                    laporanBiasaTotal={chartData.biasaTotal}
                                />
                            </div>
                            <div className="flex flex-col w-1/2 items-center text-center font-mp-bold">
                                <div className="flex text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                <div className="flex text-xl">{chartData.laporanTotal}</div>
                            </div>
                            <div className="flex flex-col w-full items-center justify-center text-base">
                                <div className="flex font-mp-bold">Laporan Khusus</div>
                                <LaporanKhusus
                                    laporanKhusus={chartData.laporanKhusus}
                                    laporanKhususTotal={chartData.khususTotal}
                                />
                            </div>
                        </div>
                    </div>
                    <Card>
                        <Performa data={performaData} />
                    </Card>
                </div>
                <div className="flex flex-col gap-8 mt-6 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                    <Card className="col-span-2">
                        <LaporanCard baseURL={baseURL} navigate={navigate} laporan={laporanData} />
                    </Card>
                    <Card className="col-span-2">
                        <Leaderboard leaderboardData={leaderboardData} />
                    </Card>
                </div>
            </div>
        </section>
    );
}

const LaporanBiasa = ({ laporanBiasa, laporanBiasaTotal }) => {
    const defaultData = {
        labels: ['Patroli', 'Pengamanan', 'Sosialisasi', 'Sinergitas'],
        series: [0, 0, 0, 0],
    };

    // Map the response data to labels and series
    const mapLaporanBiasa = (data) => ({
        'Patroli': data.patroli || 0,
        'Sosialisasi': data.sosialisasi || 0,
        'Giat Sinergitas': data.sinergitas || 0,
        'Pengamanan': data.pengamanan || 0,
    });

    const mappedData = laporanBiasa ? mapLaporanBiasa(laporanBiasa) : defaultData;

    const labels = Object.keys(mappedData);
    const series = Object.values(mappedData);

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBiasaChart laporanBiasa={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBiasaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBiasaChart = ({ laporanBiasa }) => {
    const { labels = [], series = [] } = laporanBiasa || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    return (
        <div className="w-full">
            <ApexCharts options={options} series={series} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanKhusus = ({ laporanKhusus, laporanKhususTotal }) => {
    const defaultData = {
        labels: ['Penyelundupan Orang', 'Penyelundupan Sembako', 'Penyelundupan Satwa', 'Penyelundupan Hasil Bumi',
            'Penyelundupan Senjata/Bahan Peledak', 'Narkoba', 'Pencurian Ikan/Illegal Fishing', 'Pelintas Batas Ilegal', 'Terorisme', 'Lainnya'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };

    const mapLaporanKhusus = (data) => ({
        'Penyelundupan Orang': data.orang || 0,
        'Penyelundupan Sembako': data.sembako || 0,
        'Penyelundupan Satwa': data.satwa || 0,
        'Penyelundupan Hasil Bumi': data.bumi || 0,
        'Penyelundupan Senjata/Bahan Peledak': data.senjata || 0,
        'Narkoba': data.narkoba || 0,
        'Pencurian Ikan/Illegal Fishing': data.ikan || 0,
        'Pelintas Batas Ilegal': data.batas || 0,
        'Terorisme': data.terorisme || 0,
        'Lainnya': data.lainnya || 0,
    });

    const mappedData = laporanKhusus ? mapLaporanKhusus(laporanKhusus) : defaultData;

    const labels = Object.keys(mappedData);
    const series = Object.values(mappedData);

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanKhususChart laporanKhusus={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanKhususTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanKhususChart = ({ laporanKhusus }) => {
    const { labels = [], series = [] } = laporanKhusus || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6', '#F97316', '#14B8A6', '#6B7280', '#6366F1', '#EC4899'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    return (
        <div className="w-full">
            <ApexCharts options={options} series={series} type="donut" loading="lazy" />
        </div>
    );
};

const Performa = ({ data }) => {
    const getIconAndState = (rating, state) => {
        if (state === "1") {
            return { icon: faHourglass3, state: 'Fase Evaluasi', color: 'text-black' };
        } else if (state === "2") {
            return { icon: faHourglass2, state: 'Fase Evaluasi', color: 'text-black' };
        } else if (state === "3") {
            return { icon: faHourglass1, state: 'Fase Evaluasi', color: 'text-black' };
        } else {
            switch (rating) {
                case 'Sangat Kurang':
                    return { icon: faFrown, state: 'Sangat Kurang', color: 'text-red' };
                case 'Kurang':
                    return { icon: faMeh, state: 'Kurang', color: 'text-red' };
                case 'Cukup':
                    return { icon: faSmile, state: 'Cukup', color: 'text-yellow' };
                case 'Baik':
                    return { icon: faGrin, state: 'Baik', color: 'text-green' };
                case 'Sangat Baik':
                    return { icon: faSmileBeam, state: 'Sangat Baik', color: 'text-green' };
                default:
                    return { icon: faHourglass, state: 'Loading', color: 'text-black' };
            }
        }
    };

    const { icon, state, color } = getIconAndState(data.rating, data.state);

    return (
        <div className="p-2 w-full text-base">
            <div className="flex flex-row justify-center items-center">
                <div className="font-mp-bold">Weekly Performance</div>
            </div>
            <div className="flex flex-col gap-6 mt-6 text-sm">
                <div className="flex flex-col justify-center items-center mt-4">
                    <div className="flex items-center justify-center">
                        <div className="flex flex-col items-center">
                            <FontAwesomeIcon icon={icon} className={`h-1/2 w-1/2 ${color}`} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center"> 
                    <div className="flex flex-col items-center">
                        <div className="text-sm">Performance</div>
                        <div className="text-lg font-mp-bold">{state}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LaporanCard = ({ baseURL, navigate, laporan }) => {

    const handleClick = (id) => {
        navigate(`/laporan/${id}`);
    };

    return (
        <div className="flex flex-col p-4 w-full text-base">
            <div className="flex flex-row justify-between">
                <div className="font-mp-bold">Laporan Terbaru</div>
                <Link to="/laporan" className="flex flex-row items-center text-[red]">
                    <div className="">Lihat Semua</div>
                    <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3 ml-2"/>
                </Link>
            </div>
            <div className="flex flex-col gap-5 mt-10 laptop:grid laptop:grid-cols-2 laptop:gap-5">
                {laporan.map((item) => (
                    <button key={item.id} className="relative group" onClick={() => handleClick(item.id)}>
                        <img
                            src={baseURL + item.thumbnail}
                            alt="thumbnail"
                            width={0}
                            height={0}
                            sizes="100vw"
                            className="w-full aspect-[16/9] rounded-xl object-cover"
                            loading="lazy"
                        />
                        <div className="absolute rounded-xl inset-x-0 bottom-0 bg-gradient-to-t from-[black]/[0.8] from-30% h-full w-full bg-opacity-80 flex justify-between">
                            <div className="flex flex-row text-white text-start items-end justify-center gap-4 p-4">
                                <div className="">
                                    <div className="font-gt-bold text-sm">{item.jenisLaporan}</div>
                                    <div className="flex flex-row gap-2 text-base">
                                        <p className="font-gt-italic">{item.instansi}</p>
                                    </div>
                                    <div className="text-sm text-white mt-1">{item.tglLaporan}</div>
                                </div>
                            </div>
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};


const Leaderboard = ({ leaderboardData }) => {
    // Format leaderboard data for display
    const formatLeaderboard = (data) => {
        // Convert object entries to array and sort by value in descending order
        const sortedEntries = Object.entries(data).sort(([, valueA], [, valueB]) => valueB - valueA);

        // Get top 5 entries after sorting
        const topEntries = sortedEntries.slice(0, 5);

        return topEntries.map(([key, value]) => (
            <tr key={key}>
                <td className="px-5 py-4 whitespace-normal">{key.replace(/_/g, ' ').toUpperCase()}</td>
                <td className="px-5 py-4 whitespace-normal">{value}</td>
            </tr>
        ));
    };

    return (
        <div className="p-2 w-full text-base">
            <div className="flex flex-row justify-between">
                <div className="font-mp-bold">Leaderboard (Per Triwulan Terakhir)</div>
            </div>
            <div className="mt-6 text-sm">
                <div className="min-w-full overflow-x-auto">
                    <div className="max-h-[400px] overflow-y-auto">
                        <table className="min-w-full table-auto bg-white divide-y divide-gray-dark">
                            <thead className="bg-gray-light font-mp-bold text-left">
                                <tr>
                                    <th className="px-5 py-2 text-gray-500 uppercase">
                                        User
                                    </th>
                                    <th className="px-5 py-2 text-gray-500 uppercase">
                                        Jumlah Laporan
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-dark text-left">
                                {formatLeaderboard(leaderboardData)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Home;
