import React, { useContext, useState } from 'react';
import AuthImage from './AuthImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ApiContext } from '../../context/apiConfig';
import axios from '../../api/axios';
import AuthContext from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const WelcomeMessage = () => (
    <div className="flex flex-col justify-center items-center gap-4">
        <div className="flex font-mp-bold text-xl">Silahkan Masukkan Password Baru Anda</div>
    </div>
);

const PasswordInput = ({ label, id, value, setValue, showPassword, togglePasswordVisibility, errMsg }) => (
    <div className="flex flex-col relative">
        <label htmlFor={id} className="block text-base leading-6 text-gray-900">
            {label}
        </label>
        <div className="mt-2">
            <input
                type={showPassword ? 'text' : 'password'}
                id={id}
                autoComplete="current-password"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                required
                className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 focus:ring-1 focus:ring-inset ${errMsg ? 'ring-red focus:ring-red' : 'ring-blue focus:ring-blue'} sm:text-sm sm:leading-6`}
            />
            <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="text-blue h-5 w-5 absolute top-1/2 right-3 transform translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
            />
        </div>
    </div>
);

const ResetPassword = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const userId = auth?.user?.id;
    const token = auth?.authorization?.token || auth?.accessToken || auth?.token;

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${baseURL}/api/change_password/${userId}`, { password }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            // Redirect to the login page
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Error changing password:', error);
            setErrMsg('Error changing password');
        }
    };

    return (
        <div className="flex flex-row w-full h-screen text-start font-mp">
            <AuthImage />
            <div className="bg-white text-light-black w-4/12 py-24 px-7">
                <div className="flex flex-col h-full justify-between">
                    <WelcomeMessage />
                    <form onSubmit={handleSubmit} className="flex flex-col gap-10">
                        <PasswordInput
                            label="Password"
                            id="password"
                            value={password}
                            setValue={setPassword}
                            showPassword={showPassword}
                            togglePasswordVisibility={togglePasswordVisibility}
                            errMsg={errMsg}
                        />
                        <div className="flex flex-col gap-4">
                            <button
                                type="submit"
                                className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 rounded-lg mt-6"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="bg-red text-white flex justify-center items-center h-12 rounded-lg"
                            >
                                Cancel
                            </button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
