import React, { useContext, useEffect, useState } from 'react'
import Tiles from '../../components/reusable/Tiles';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const Satker = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;

    const [satker, setSatker] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/list_satker_mabes`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                // Assuming the API returns an array of satker objects
                const uniqueSatker = [...new Set(response.data.satker.map(item => item.satker))];

                const urlMapping = {
                    'BAINTELKAM': 'baintelkam',
                    'BARESKRIM': 'bareskrim',
                    'BAHARKAM': 'baharkam',
                    'LEMDIKPOL': 'lemdikpol',
                    'ASOPS': 'asops',
                    'ASRENA': 'asrena',
                    'ASSDM': 'assdm',
                    'ASLOG': 'aslog',
                    'KORBRIMOB': 'korbrimob',
                    'DIVHUBINTER': 'divhubinter',
                    'DIVKUM': 'divkum',
                    'DIVTELEMATIKA': 'divtelematika',
                    'DIVHUMAS': 'divhumas',
                    'PUSLITBANG': 'puslitbang',
                    'PUSDOKKES': 'pusdokkes',
                    'PUSKEU': 'puskeu',
                    'SETUM': 'setum'
                  };
                
                const processedData = uniqueSatker.map((item, index) => ({
                    id: index,
                    satker: item,
                    url: `/satker/${urlMapping[item] || item.toLowerCase().replace(/\s+/g, '')}`
                }));

                setSatker(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token, baseURL]);

    return (
        <section className="h-auto">
            <div className="flex flex-row justify-between gap-4 laptop:w-full">
                <div className="font-mp-bold text-xl text-gray-light">SATKER MABES POLRI</div>
                {(auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1) ||
                    (auth?.user?.instansi === satker.instansi) ? (
                        <>
                            <Link to="/satker/addSatker" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4"></FontAwesomeIcon>
                                <div className="hidden laptop:flex">Input SATKER</div>
                            </Link>
                        </>
                    ) : null}
            </div>
            <div className="mt-8 font-mp-bold">
                <div className="grid grid-cols-2 gap-4 laptop:grid laptop:grid-cols-5">
                    {
                        satker.map((item) => (
                            <Tiles link={item.url} key={item.id}>
                                <div className="flex items-center justify-center">
                                    {/* You can add an icon or image here if available */}
                                </div>
                                <div className="flex mt-4 text-center">{item.satker}</div>
                            </Tiles>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default Satker;