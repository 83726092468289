import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from 'jwt-decode';

const RequireAuth = ({ allowedRoles }) => {
    const { auth, setAuth } = useAuth();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (auth?.accessToken) {
            const decodedToken = jwtDecode(auth.accessToken);

            // Check if the token has expired
            if (decodedToken.exp * 1000 < Date.now()) {
                setAuth(null);
                localStorage.removeItem("auth");
                setShowModal(true);
            }

            // Optionally, set an interval to check the token's validity periodically
            const interval = setInterval(() => {
                const updatedAuth = JSON.parse(localStorage.getItem("auth"));
                if (updatedAuth?.accessToken) {
                    const updatedDecodedToken = jwtDecode(updatedAuth.accessToken);
                    if (updatedDecodedToken.exp * 1000 < Date.now()) {
                        setAuth(null);
                        localStorage.removeItem("auth");
                        setShowModal(true);
                    }
                }
            }, 5000); // Check every 5 seconds

            return () => clearInterval(interval);
        }
    }, [auth, setAuth]);

    const userAccessLevel = auth?.user?.access_level;
    const isAuthorized = userAccessLevel !== undefined && allowedRoles.includes(userAccessLevel);

    if (showModal) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
                <div className="flex flex-col p-6 items-center justify-between gap-6 bg-white rounded-xl shadow-lg">
                    <div className="flex text-lg font-mp-bold">Sesi Telah Berakhir, Silahkan Login Kembali</div>
                    <div className="flex flex-row justify-center">
                        <button
                            onClick={() => {
                                setShowModal(false);
                                // Redirect to login
                                window.location.href = '/login';
                            }}
                            className="bg-blue hover:bg-green text-white px-4 py-2 rounded-lg"
                        >
                            Kembali ke Login
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        isAuthorized
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: '/' }} replace />
    );
};

export default RequireAuth;


