import React, { useCallback, useContext, useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';

const RekapManual = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const [loading, setLoading] = useState(true);
    const [instansiOptions, setInstansiOptions] = useState([]);
    const [formData, setFormData] = useState({
        instansi: '',
        start_date: '',
        end_date: '',
    });

    const [errors, setErrors] = useState({});
    const [chartData, setChartData] = useState({
        laporanBiasa: [],
        laporanKhusus: [],
        laporanTotal: 0,
        biasaTotal: 0,
        khususTotal: 0,
    });

    const [dataSubmitted, setDataSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear the error message for the field being changed
    };

    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];
    };

    const fetchData = useCallback(async () => {
        try {
            const { instansi, start_date, end_date } = formData;
            if (!start_date || !end_date || !instansi) return;

            // Format dates
            const formattedStartDate = formatDate(start_date);
            const formattedEndDate = formatDate(end_date);

            const response = await axios.get(
                `${baseURL}/api/rekap_manual/${formattedStartDate}&${formattedEndDate}&${instansi}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setChartData({
                laporanBiasa: response.data.laporan_biasa || [],
                laporanKhusus: response.data.laporan_khusus || [],
                laporanTotal: response.data.total_laporan || 0,
                biasaTotal: response.data.kategori_laporan?.biasa || 0,
                khususTotal: response.data.kategori_laporan?.khusus || 0,
                plbn: response.data.plbn || {},
            });

            setLoading(false);
            setDataSubmitted(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }, [baseURL, formData, token]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.instansi) {
            newErrors.instansi = 'Instansi is required';
        }
        if (!formData.start_date) {
            newErrors.start_date = 'Start date is required';
        }
        if (!formData.end_date) {
            newErrors.end_date = 'End date is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetchData();
        }
    };

    useEffect(() => {
        // Fetch instansi options on mount
        const fetchInstansiOptions = async () => {
            try {
                const instansiResponse = await axios.get(`${baseURL}/api/dropdown_polres_plbn`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                setInstansiOptions([
                    { value: '', label: '-- Pilih Opsi --' },
                    ...instansiResponse.data.satuan.map(satuan => ({
                        value: satuan.instansi,
                        label: satuan.instansi,
                    })),
                ]);
            } catch (error) {
                console.error('Error fetching instansi options:', error);
            }
        };

        fetchInstansiOptions();
    }, [baseURL, token]);

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Rekap Manual</div>
            <div className="mt-8">
                <form onSubmit={handleSubmit} className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="flex flex-col gap-6 px-4">
                            <div className="text-lg font-mp-bold justify-start">
                                Instansi & Range Tanggal
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="instansi" className="block text-base leading-6 text-gray">Instansi</label>
                                <div className="mt-2">
                                    <FormSelect
                                        name="instansi"
                                        value={formData.instansi}
                                        handleChange={handleChange}
                                        options={instansiOptions}
                                    />
                                    {errors.instansi && <span className="text-red text-sm">{errors.instansi}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="start_date" className="block text-base leading-6 text-gray">Dari</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        autoComplete="given-name"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-box focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.start_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.start_date && <span className="text-red text-sm">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="end_date" className="block text-base leading-6 text-gray">Sampai</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        autoComplete="given-name"
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.end_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.end_date && <span className="text-red text-sm">{errors.end_date}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">Submit</button>
                        </div>
                    </div>
                    {dataSubmitted && (
                        loading ? (
                            <div className="overflow-auto rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                    Loading...
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                    <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                        <div className="text-lg font-mp-bold justify-start">
                                            Laporan
                                        </div>
                                        <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Biasa</div>
                                                <LaporanBiasa
                                                    laporanBiasa={chartData.laporanBiasa}
                                                    laporanBiasaTotal={chartData.biasaTotal}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center text-center font-mp-bold">
                                                <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                                <div className="text-xl">{chartData.laporanTotal}</div>
                                            </div>
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Khusus</div>
                                                <LaporanKhusus
                                                    laporanKhusus={chartData.laporanKhusus}
                                                    laporanKhususTotal={chartData.khususTotal}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </form>
            </div>
        </section>
    );
};

const FormSelect = ({ label, name, value, handleChange, options }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <select
                id={name}
                name={name}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                value={value}
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    </div>
);

const LaporanBiasa = ({ laporanBiasa, laporanBiasaTotal }) => {
    const defaultData = {
        labels: ['Patroli', 'Pengamanan', 'Sosialisasi', 'Sinergitas'],
        series: [0, 0, 0, 0],
    };

    const mapLaporanBiasa = (data) => ({
        'Patroli': data.patroli || 0,
        'Pengamanan': data.pengamanan || 0,
        'Sosialisasi': data.sosialisasi || 0,
        'Sinergitas': data.sinergitas || 0,
    });

    const mappedData = laporanBiasa ? mapLaporanBiasa(laporanBiasa) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBiasaChart laporanBiasa={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBiasaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBiasaChart = ({ laporanBiasa }) => {
    const { labels = [], series = [] } = laporanBiasa || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanKhusus = ({ laporanKhusus, laporanKhususTotal }) => {
    const defaultData = {
        labels: ['Penyelundupan Orang', 'Penyelundupan Sembako', 'Penyelundupan Satwa', 'Penyelundupan Hasil Bumi',
            'Penyelundupan Senjata/Bahan Peledak', 'Narkoba', 'Pencurian Ikan/Illegal Fishing', 'Pelintas Batas Ilegal', 'Terorisme', 'Lainnya'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };

    const mapLaporanKhusus = (data) => ({
        'Penyelundupan Orang': data.orang || 0,
        'Penyelundupan Sembako': data.sembako || 0,
        'Penyelundupan Satwa': data.satwa || 0,
        'Penyelundupan Hasil Bumi': data.bumi || 0,
        'Penyelundupan Senjata/Bahan Peledak': data.senjata || 0,
        'Narkoba': data.narkoba || 0,
        'Pencurian Ikan/Illegal Fishing': data.ikan || 0,
        'Pelintas Batas Ilegal': data.batas || 0,
        'Terorisme': data.terorisme || 0,
        'Lainnya': data.lainnya || 0,
    });

    const mappedData = laporanKhusus ? mapLaporanKhusus(laporanKhusus) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanKhususChart laporanKhusus={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanKhususTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanKhususChart = ({ laporanKhusus }) => {
    const { labels = [], series = [] } = laporanKhusus || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6', '#F97316', '#14B8A6', '#6B7280', '#6366F1', '#EC4899'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

export default RekapManual
