import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const PoldaLaporan = () => {
    const { polresName } = useParams();
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const location = useLocation();

    // Access the passed data
    const { poldaData } = location.state || {};

    const [total, setTotal] = useState(0);
    const [laporan, setLaporan] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedLaporanId, setSelectedLaporanId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [loading, setLoading] = useState(true);

    const toUpperCase = (str) => {
        return str ? str.toUpperCase() : '';
    };

    const fetchLaporan = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            // Ensure the polres property is accessed correctly
            const endpoint = `/api/laporan_instansi/${polresName}?page=${page}`;
            const url = `${baseURL}${endpoint}`;
    
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            const { total_laporan, laporan } = response.data;
            const { data, links, current_page, last_page } = laporan;
            setTotal(total_laporan); // Update the total state with the correct value
            setLaporan(data);
            setPaginationLinks(links);
            setCurrentPage(current_page);
            setTotalPages(last_page);
        } catch (error) {
            console.error('There was an error fetching the report data!', error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, token, polresName]);

    useEffect(() => {
        fetchLaporan(1);
    }, [fetchLaporan]);

    const deleteLaporan = useCallback(async (laporanId) => {
        try {
            await axios.delete(`${baseURL}/api/delete_laporan/${laporanId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            window.location.reload(); // Reload the page after deletion
        } catch (error) {
            console.error('There was an error deleting the report!', error);
        }
    }, [baseURL, token]);

    const handleEditClick = (laporanId) => {
        navigate(`/laporan/editlaporan/${laporanId}`);
    };

    const handleDeleteClick = (laporanId) => {
        setSelectedLaporanId(laporanId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deleteLaporan(selectedLaporanId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            fetchLaporan(page);
        }
    };

    const handleBack = () => {
        navigate(`/polda/${poldaData}`, { state: { poldaData: poldaData } });
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
                <div className="flex flex-row justify-between">
                    <button
                        onClick={handleConfirmDelete}
                        className="bg-green text-white px-4 py-2 rounded-lg"
                    >
                        Ya
                    </button>
                    <button
                        onClick={handleCancelDelete}
                        className="bg-red text-white px-4 py-2 rounded-lg"
                    >
                        Tidak
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="h-auto">
            <div className="flex flex-row justify-between items-center">
                <div className="font-mp-bold text-xl text-gray-light">LAPORAN {polresName}</div>
            </div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between gap-4 laptop:w-full">
                            <button className="flex flex-row gap-3 items-center" onClick={() => handleBack()}>
                                <FontAwesomeIcon icon={faArrowLeft} className="text-blue h-6 w-6"></FontAwesomeIcon>
                                <div className="">Back</div>
                            </button>
                            <div className="flex flex-row gap-2 text-lg">
                                <div className="flex">Total Laporan:</div>
                                <div className="flex font-mp-bold">{total}</div> {/* This will show the total number of reports */}
                            </div>
                        </div>
                        <div className="mt-6 text-base">
                            {loading ? (
                                <div className="text-center py-4">Loading data...</div>
                            ) : (
                                <div className="min-w-full overflow-x-auto">
                                    <div className="overflow-y-auto">
                                        <table className="min-w-full table-auto divide-y divide-gray-dark">
                                            <thead className="bg-gray-light">
                                                <tr>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JUDUL LAPORAN</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">INSTANSI</th>
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">WAKTU DIBUAT</th>
                                                    {(auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1) ||
                                                        (auth?.user?.instansi === laporan.data?.instansi) ? (
                                                            <>
                                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                            </>
                                                        ) : null}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-dark">
                                                {laporan.length > 0 ? (
                                                    laporan.map((report, index) => (
                                                        <tr key={report.id}>
                                                            <td className="px-5 py-2 whitespace-normal">{(currentPage - 1) * laporan.length + index + 1}</td>
                                                            <td className="px-5 py-4 whitespace-normal overflow-hidden">
                                                                <Link to={`/laporan/${report.id}`}>{toUpperCase(report.judulLaporan)}</Link>
                                                            </td>
                                                            <td className="px-5 py-2 whitespace-normal">{report.instansi}</td>
                                                            <td className="px-5 py-2 whitespace-normal">{new Date(report.created_at).toLocaleDateString('en-GB')}</td>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                            {(auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1) ||
                                                                (auth?.user?.instansi === report.instansi) ? (
                                                                    <>
                                                                        <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                className="text-white h-4 w-4"
                                                                                onClick={() => handleEditClick(report.id)}
                                                                            />
                                                                        </button>
                                                                        <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}
                                                                                className="text-white h-4 w-4"
                                                                                onClick={() => handleDeleteClick(report.id)}
                                                                            />
                                                                        </button>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center py-4">
                                                            No data available.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                        {renderPagination()}
                    </div>
                </div>
            </div>

            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
}

export default PoldaLaporan;
