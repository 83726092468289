import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthImage from './AuthImage';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const LOGIN_URL = '/login';

const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ username: user, password: pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const { user: userData, authorization } = response.data;
            const token = authorization?.token;
            const access_level = userData?.access_level;

            setAuth({
                accessToken: token,
                user: {
                    ...userData,
                    access_level,
                },
            });

            localStorage.setItem("auth", JSON.stringify({
                accessToken: token,
                user: userData,
            }));

            setUser('');
            setPwd('');
            navigate(from, { replace: true });

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    };

    return (
        <div className="flex flex-col laptop:flex-row w-full h-screen font-mp text-start">
            <AuthImage />
            <div className="flex flex-col h-full justify-evenly gap-4 bg-white text-black-light w-full laptop:w-4/12 px-10">
                <Image />
                <WelcomeMessage />
                <LoginForm
                    userRef={userRef}
                    errRef={errRef}
                    user={user}
                    setUser={setUser}
                    pwd={pwd}
                    setPwd={setPwd}
                    errMsg={errMsg}
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                    handleSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

const Image = () => (
    <div className="flex flex-row justify-center items-center gap-6">
        <img
            src="/images/tribrata.png"
            alt=""
            width={0}
            height={0}
            sizes="100vw"
            loading="lazy" 
            className="flex w-24 laptop:w-20"
        />
        <img
            src="/images/dhi.png"
            alt=""
            width={0}
            height={0}
            sizes="100vw"
            loading="lazy"
            className="flex w-20 laptop:w-16"
        />
    </div>
)

const WelcomeMessage = () => (
    <div className="flex flex-col justify-center items-center gap-4">
        <div className="flex font-mp-bold text-xl">Selamat Datang</div>
        <div className="flex text-lg text-center">
            Silahkan masukkan identitas anda untuk masuk ke dalam aplikasi IMBAS
        </div>
    </div>
);

const LoginForm = ({
    userRef,
    errRef,
    user,
    setUser,
    pwd,
    setPwd,
    errMsg,
    showPassword,
    togglePasswordVisibility,
    handleSubmit
}) => (
    <form onSubmit={handleSubmit} className="flex flex-col h-1/2 gap-5">
        <InputField
            label="Username"
            id="username"
            type="text"
            value={user}
            setValue={setUser}
            ref={userRef}
            errMsg={errMsg}
        />
        <PasswordInput
            label="Password"
            id="password"
            value={pwd}
            setValue={setPwd}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
            errMsg={errMsg}
        />
        {/* <ForgotPasswordLink /> */}
        <ErrorMessage ref={errRef} errMsg={errMsg} />
        <SubmitButton />
    </form>
);



const InputField = React.forwardRef(({ label, id, type, value, setValue, errMsg }, ref) => (
    <div className="flex flex-col">
        <label htmlFor={id} className="block justify-start text-base leading-6 text-gray">
            {label}
        </label>
        <div className="mt-2">
            <input
                type={type}
                id={id}
                autoComplete="given-name"
                ref={ref}
                onChange={(e) => setValue(e.target.value)}
                value={value}
                required
                className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 focus:ring-1 focus:ring-inset ${errMsg ? 'ring-red focus:ring-red' : 'ring-blue focus:ring-blue'} sm:text-sm sm:leading-6`}
            />
        </div>
    </div>
));

const PasswordInput = ({ label, id, value, setValue, showPassword, togglePasswordVisibility, errMsg }) => (
    <div className="flex flex-col relative">
        <label htmlFor={id} className="block text-base leading-6 text-gray-900">
            {label}
        </label>
        <div className="mt-2">
            <input
                type={showPassword ? 'text' : 'password'}
                id={id}
                autoComplete="current-password"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                required
                className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 focus:ring-1 focus:ring-inset ${errMsg ? 'ring-red focus:ring-red' : 'ring-blue focus:ring-blue'} sm:text-sm sm:leading-6`}
            />
            <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="text-blue h-5 w-5 absolute top-1/2 right-3 transform translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
            />
        </div>
    </div>
);

const ErrorMessage = React.forwardRef(({ errMsg }, ref) => (
    <p ref={ref} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
));

const SubmitButton = () => (
    <button className="bg-blue hover:bg-green text-white flex justify-center items-center p-2 rounded-lg">
        Sign In
    </button>
);

export default Login;
