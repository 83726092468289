import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const AdminPolda = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [poldas, setPoldas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedPoldaId, setSelectedPoldaId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 20;

    const fetchPoldas = useCallback(async (page = 1, search = '') => {
        setLoading(true);
        try {
            const endpoint = search
                ? `/api/search_polda/${search}?page=${page}`
                : `/api/list_polda?page=${page}`;

            const url = `${baseURL}${endpoint}`;
            console.log(`Fetching poldas from: ${url}`);

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const poldaData = response.data.polda;
            setPoldas(poldaData.data);
            setPaginationLinks(poldaData.links);
            setCurrentPage(poldaData.current_page);
            setTotalPages(poldaData.last_page);
            console.log('Polda data fetched successfully', poldaData);
        } catch (error) {
            console.error('There was an error fetching the polda data!', error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchPoldas(1, searchKeyword);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [fetchPoldas, searchKeyword]);

    const deletePolda = useCallback((poldaId) => {
        axios.delete(`${baseURL}/api/delete_polda/${poldaId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(() => {
            fetchPoldas(currentPage, searchKeyword);
        })
        .catch(error => {
            console.error('There was an error deleting the polda!', error);
        });
    }, [token, baseURL, currentPage, fetchPoldas, searchKeyword]);

    const handleEditClick = (poldaId) => {
        navigate(`/admin/editpolda/${poldaId}`);
    };

    const handleDeleteClick = (poldaId) => {
        setSelectedPoldaId(poldaId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deletePolda(selectedPoldaId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            fetchPoldas(page, searchKeyword);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );
    
    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Manajemen Polda</div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-row w-full justify-between">
                            <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
                            <Link to="/admin/addpolda" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4" />
                                <div className="hidden laptop:flex">Input Polda</div>
                            </Link>
                        </div>
                        <div className="text-base">
                            {loading ? (
                                <div className="text-center py-4">Loading data...</div>
                            ) : (
                                <PoldaTable 
                                    poldas={poldas}
                                    itemsPerPage={itemsPerPage}
                                    handleEditClick={handleEditClick} 
                                    handleDeleteClick={handleDeleteClick}
                                    currentPage={currentPage} 
                                />
                            )}
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

const SearchBar = ({ searchKeyword, setSearchKeyword }) => (
    <div className="flex w-1/2 pt-4 pb-2 bg-white text-black-light">
        <div className="flex flex-col relative mt-1 gap-4">
            <input 
                type="text" 
                id="search" 
                placeholder="Search..." 
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="block w-full rounded-md border-0 px-2 py-1 mr-20 shadow-sm ring-1 ring-black-light placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-black-light sm:text-sm sm:leading-6" 
            />
        </div>
    </div>
);

const PoldaTable = ({ poldas, currentPage, itemsPerPage, handleEditClick, handleDeleteClick }) => {
    const startingIndex = (currentPage - 1) * itemsPerPage + 1;
    return (
        <div className="mt-6 text-base">
            <div className="min-w-full overflow-x-auto">
                <div className="overflow-y-auto">
                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                        <thead className="bg-gray-light">
                            <tr>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POLDA</th>
                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-dark">
                        {poldas.length > 0 ? (
                            poldas.map((polda, index) => (
                                <tr key={polda.id}>
                                    <td className="px-5 py-2 whitespace-normal">{startingIndex + index}</td>
                                    <td className="px-5 py-2 whitespace-normal">{polda.polda}</td>
                                    <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                        <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto" onClick={() => handleEditClick(polda.id)}>
                                            <FontAwesomeIcon icon={faEdit} className="text-white h-4 w-4" />
                                        </button>
                                        <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto" onClick={() => handleDeleteClick(polda.id)}>
                                            <FontAwesomeIcon icon={faTrash} className="text-white h-4 w-4" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    No data available.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded-lg shadow-lg">
            <p>Are you sure you want to delete this polda?</p>
            <div className="mt-4 flex justify-end">
                <button className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2" onClick={handleCancelDelete}>
                    Cancel
                </button>
                <button className="bg-red-500 text-white px-4 py-2 rounded-lg" onClick={handleConfirmDelete}>
                    Delete
                </button>
            </div>
        </div>
    </div>
);

export default AdminPolda;
