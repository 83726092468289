import React from 'react';

let baseURL;

if (window.location.hostname === "localhost") {
    baseURL = process.env.REACT_APP_LOCAL_API_URL;
} else {
    baseURL = process.env.REACT_APP_NETWORK_API_URL;
}

export const ApiContext = React.createContext(baseURL);