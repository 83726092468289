import React, { useContext, useEffect, useState } from 'react'
import Tiles from '../../components/reusable/Tiles';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const Kementerian = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;

    const [kementerian, setKementerian] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/list_kementrian`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                const uniqueKementerian = [...new Set(response.data.list_kementrian.map(item => item.kementrian))];
                
                const urlMapping = {
                    'KEMENKOPOLHUKAM': 'kemenpolhukam',
                    'KEMENKO EKONOMI': 'koorekonomi',
                    'KEMENKO PMK': 'kemenko',
                    'BNPP': 'bnpp',
                    'KEMENLU': 'kemenlu',
                    'KEMHAN': 'pertahanan',
                    'KEMENKUMHAM': 'humham',
                    'KEMENKEU': 'keuangan',
                    'KEMENTERIAN PUPR': 'pekerjaumum',
                    'KEMENHUB': 'perhubungan',
                    'KEMENTERIAN LHK': 'lingkuphutan',
                    'KKP': 'lautikan',
                    'BAPPENAS': 'pembangunan',
                    'KEMENDESA': 'pembangunantertinggal',
                    'KEMENKES': 'kesehatan',
                    'KEMENTERIAN PERTANIAN': 'tani',
                    'TNI': 'tni',
                    'POLRI': 'polri',
                    'BIN': 'bin',
                    'BIG': 'geospasial',
                    'BAKAMLA': 'bakamla',
                    'BNN': 'bnn',
                    'BNPT': 'bnpt'
                };
                
                const processedData = uniqueKementerian.map((item, index) => ({
                    id: index,
                    kementrian: item,
                    url: `/kemenlem/${urlMapping[item] || item.toLowerCase().replace(/\s+/g, '-')}`
                }));

                setKementerian(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token, baseURL]);

    return (
        <section className="h-auto">
            <div className="flex flex-row justify-between gap-4 laptop:w-full">
                <div className="font-mp-bold text-xl text-gray-light">Kementerian dan Lembaga</div>
                {(auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1) ||
                    (auth?.user?.instansi === kementerian.instansi) ? (
                        <>
                            <Link to="/kemenlem/addKemen" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4"></FontAwesomeIcon>
                                <div className="hidden laptop:flex">Input K/L</div>
                            </Link>
                        </>
                 ) : null}
            </div>
            <div className="mt-8 font-mp-bold">
                <div className="grid grid-cols-2 gap-4 laptop:grid laptop:grid-cols-5">
                    {
                        kementerian.map((item) => (
                            <Tiles link={item.url} key={item.id}>
                                <div className="flex items-center justify-center">
                                    {/* You can add an icon or image here if available */}
                                </div>
                                <div className="flex mt-4 text-center">{item.kementrian}</div>
                            </Tiles>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default Kementerian;
