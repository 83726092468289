import React, { useState, useEffect, useRef, useContext } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faHouse, 
  faLocationDot, 
  faLandmark, 
  faUserGroup, 
  faMap, 
  faRoadBarrier, 
  faPassport, 
  faFileLines, 
  faPaste, 
  faUser, 
  faChartPie,
  faBars, 
  faBell, 
  faSignOutAlt,
  faBook,
  faTimes,
  faLock
} from "@fortawesome/free-solid-svg-icons";

import AuthContext from '../context/AuthProvider';
import { ApiContext } from '../context/apiConfig';
import axios from '../api/axios';
import { formatDistanceToNow } from 'date-fns';
import { id } from 'date-fns/locale';

const Dashboard = ({ isHidden, onSidebarToggle }) => {
    const [isSidebarHidden, setSidebarHidden] = useState(window.innerWidth < 1024); // Hidden by default in mobile screens

    useEffect(() => {
        // Function to handle screen resize
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setSidebarHidden(true); // Hide the sidebar in mobile view
            } else {
                setSidebarHidden(false); // Show the sidebar in laptop/desktop view
            }
        };

        // Set up event listener if in mobile view
        if (window.innerWidth < 1024) {
            window.addEventListener('resize', handleResize);
        }

        // Initial check on mount
        handleResize();

        // Clean up the event listener when component unmounts or when in non-mobile view
        return () => {
            if (window.innerWidth < 1024) {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    const handleSidebarToggle = () => setSidebarHidden(prev => !prev);

    return (
        <div className="font-mp flex flex-row min-h-screen min-w-screen text-black-light bg-gradient-to-br from-blue-gr via-blue-gr to-yellow-gr relative">
            <Sidebar isHidden={isSidebarHidden || isHidden} onSidebarToggle={handleSidebarToggle} />
            <div className={`flex flex-col w-full text-start transition-all duration-300 ${isSidebarHidden ? 'w-full' : 'laptop:w-[80%]'}`}>
                <Navbar isSidebarHidden={isSidebarHidden} onSidebarToggle={handleSidebarToggle} />
                <section className={`flex flex-col relative py-6 ${isSidebarHidden ? 'w-11/12 laptop:w-10/12' : 'w-11/12'} layout`}>
                    <Outlet />
                </section>
            </div>
        </div>
    );
};

const Sidebar = ({ isHidden, onSidebarToggle }) => {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const sidebarRef = useRef(null);

    let menus = [
        { name: "Home", icon: faHouse, link: "/" },
        { name: "POLDA Perbatasan", icon: faLocationDot, link: "/polda" },
        { name: "Kementerian & Lembaga", icon: faLandmark, link: "/kemenlem" },
        { name: "SATKER MABES POLRI", icon: faUserGroup, link: "/satker" },
        { name: "Badan Batas Provinsi", icon: faMap, link: "/batasprov" },
        { name: "PLBN", icon: faRoadBarrier, link: "/plbn" },
        { name: "TPI", icon: faPassport, link: "/tpi" },
        { name: "Laporan", icon: faFileLines, link: "/laporan" },
        { name: "LOTAS", icon: faPaste, link: "/lotas" },
        { name: "Administrator", icon: faUser, link: "/admin" },
        { name: "Rekap Laporan", icon: faChartPie, link: "/rekap" }
    ];

    if (auth?.user?.access_level === "3") {
        menus = menus.filter(item => item.name !== "LOTAS" && item.name !== "Administrator" && item.name !== "Rekap Laporan");
    } else if (auth?.user?.access_level === "2") {
        menus = menus.filter(item => item.name !== "LOTAS" && item.name !== "Administrator");
    }

    const logout = async () => {
        setAuth({});
        localStorage.removeItem('auth');
        navigate('/login');
    };

    return (
        <section
            ref={sidebarRef}
            className={`bg-blue text-white p-5 gap-4 transition-all duration-300 ease-in-out
            ${isHidden ? 'hidden' : 'fixed z-40 top-0 left-0 h-full w-3/4 overflow-y-auto tablet:w-1/2 laptop:h-auto laptop:w-[20%] laptop:static laptop:flex laptop:flex-col text-sm'}`}
        >
            {/* Close button for mobile */}
            <button className="laptop:hidden absolute top-4 right-4 text-white" onClick={onSidebarToggle}>
                <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
            </button>

            <div className="flex flex-row justify-between items-center text-center">
                <img src="/images/tribrata.png" alt="Tribrata" className="h-5/12 w-4/12 aspect-[16/9] object-contain" loading="lazy" />
                <div className="font-mp-bold text-xl text-red">IMBAS</div>
                <img src="/images/imbas.png" alt="Imbas" className="h-5/12 w-5/12 aspect-[16/9] object-contain" loading="lazy" />
            </div>

            <div className="flex flex-col mt-14 gap-3 laptop:text-base">
                {menus.map((item, id) => (
                    <Link to={item.link} key={id} className="flex flex-row items-center p-3 gap-3 rounded-lg hover:bg-blue-light">
                        <FontAwesomeIcon icon={item.icon} className="h-4 w-4" />
                        <div className="text-start">{item.name}</div>
                    </Link>
                ))}
                <Link to="panduan" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center p-3 gap-3 rounded-lg hover:bg-blue-light">
                    <FontAwesomeIcon icon={faBook} className="h-4 w-4" />
                    <div>Buku Panduan</div>
                </Link>
                <Link to="reset-password" className="flex flex-row items-center p-3 gap-3 rounded-lg hover:bg-blue-light">
                    <FontAwesomeIcon icon={faLock} className="h-4 w-4" />
                    <div>Reset Password</div>
                </Link>
                <Link onClick={logout} className="flex flex-row items-center p-3 gap-3 rounded-lg hover:bg-blue-light">
                    <FontAwesomeIcon icon={faSignOutAlt} className="h-4 w-4" />
                    <div>Sign Out</div>
                </Link>
            </div>
        </section>
    );
};

const Navbar = ({ onSidebarToggle, isSidebarHidden }) => (
    <section className="flex h-[7%] bg-white border-b border-b-gray-dark transition-all duration-300 ease-in-out">
        <div className={`flex flex-row layout-nav justify-between items-center ${isSidebarHidden ? 'w-11/12 laptop:w-10/12' : 'w-11/12'}`}>
            <button className="transition-all duration-300 ease-in-out" onClick={onSidebarToggle}>
                <FontAwesomeIcon icon={faBars} className="h-5 w-5" />
            </button>
            <div className="flex flex-row items-center gap-4">
                <Notification />
                <Profile />
            </div>
        </div>
    </section>
);

const Profile = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const dropdownRef = useRef(null);
  
    const userName = auth?.user?.name;
    const userInstansi = auth?.user?.instansi;
    const userLogo = auth?.user?.logo;
  
    return (
        <div className="relative ml-10 py-2" ref={dropdownRef}>
            <div className="flex flex-row gap-3 justify-start">
                <div className="flex justify-center items-center rounded-full bg-blue p-2 h-12 w-12">
                    <img
                        src={baseURL + userLogo}
                        alt="thumbnail"
                        width={0}
                        height={0}
                        sizes="100vw"
                        className="aspect-[4/3] object-contain w-full h-full"
                        loading="lazy"
                    />
                </div>
            <div className="hidden laptop:flex laptop:flex-col text-center">
                <span className="flex text-base">{userInstansi}</span>
                <span className="flex font-mp-bold text-base">{userName}</span>
            </div>
        </div>
        
      </div>
    );
};

const Notification = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const userInstansi = auth?.user?.instansi;

    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [newNotificationCount, setNewNotificationCount] = useState(0);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (userInstansi) {
            const storedNotifications = JSON.parse(localStorage.getItem(`notifications_${userInstansi}`) || '[]');
            const storedIsOpen = JSON.parse(localStorage.getItem(`isNotificationOpen_${userInstansi}`) || 'false');
            setNotifications(storedNotifications);
            setIsOpen(storedIsOpen);

            const lastOpenTime = new Date(localStorage.getItem(`lastOpenTime_${userInstansi}`) || 0);
            const newNotifications = storedNotifications.filter(notification => new Date(notification.created_at) > lastOpenTime);
            setNewNotificationCount(newNotifications.length);
        }
    }, [userInstansi]);

    useEffect(() => {
        if (userInstansi) {
            localStorage.setItem(`notifications_${userInstansi}`, JSON.stringify(notifications));
        }
    }, [notifications, userInstansi]);

    useEffect(() => {
        if (userInstansi) {
            localStorage.setItem(`isNotificationOpen_${userInstansi}`, JSON.stringify(isOpen));
        }
    }, [isOpen, userInstansi]);

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
        if (!isOpen && userInstansi) {
            setNewNotificationCount(0);
            localStorage.setItem(`lastOpenTime_${userInstansi}`, new Date().toISOString());
        }
    };

    useEffect(() => {
        const handleOutsideClick = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                if (!isOpen) return;
                setIsOpen(false);
            }
        };
        window.addEventListener("click", handleOutsideClick);
        return () => window.removeEventListener("click", handleOutsideClick);
    }, [isOpen]);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!userInstansi) return;

            try {
                const response = await axios.get(`${baseURL}/api/notifikasi`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const newNotifications = response.data.notifikasi.data;
                
                const lastOpenTime = new Date(localStorage.getItem(`lastOpenTime_${userInstansi}`) || 0);
                const newStoredNotifications = newNotifications.filter(notification => 
                    new Date(notification.created_at) > lastOpenTime
                );

                setNotifications(newNotifications);
                setNewNotificationCount(newStoredNotifications.length);

            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        if (token && userInstansi) {
            fetchNotifications();
        }
    }, [baseURL, token, userInstansi]);

    if (!userInstansi) return null;

    return (
        <div className="relative ml-10 py-2" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="relative flex flex-row gap-3 justify-start">
                <div className="flex justify-center items-center p-2 w-auto">
                    <FontAwesomeIcon icon={faBell} className="h-5 w-5" />
                    {newNotificationCount > 0 && (
                        <span className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-red text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                            {newNotificationCount}
                        </span>
                    )}
                </div>
            </button>
            {isOpen && (
                <NotificationDropdown notifications={notifications} />
            )}
        </div>
    );
};

const NotificationDropdown = ({ notifications }) => {
    const limitedNotifications = notifications.slice(0, 4);

    return (
        <div className="z-10 origin-center absolute right-0 mt-2 laptop:w-[20rem] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 text-gray-300 bg-white">
            <div className="py-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button">
                {limitedNotifications.map(notification => (
                    <NotificationItem key={notification.id} notification={notification} />
                ))}
                <div className="px-4 py-3 flex justify-center items-center">
                    <a href="/notifikasi" className="font-bold text-sm">Lihat Semua Notifikasi</a>
                </div>
            </div>
        </div>
    );
};

const NotificationItem = ({ notification }) => {
    const baseURL = useContext(ApiContext);
    const timeAgo = formatDistanceToNow(new Date(notification.created_at), { addSuffix: true, locale: id });

    return (
        <a href={`/laporan/${notification.laporan_id}`} className="flex flex-row gap-4 items-center px-4 py-3  border-b border-gray-dark">
            <div className="flex justify-center items-center rounded-full bg-blue p-2 h-10 w-12">
                <img
                    src={baseURL + notification.logo_dari}
                    alt="User Logo"
                    className="aspect-[4/3] object-contain w-full h-full"
                />
            </div>
            <div className="ml-3 flex flex-col justify-start gap-1">
                <div className="text-base">
                    <span className="">{notification.action_log}</span>
                </div>
                <span className="text-sm">{timeAgo}</span>
            </div>
        </a>
    );
};

export default Dashboard
