import React, { useContext, useEffect, useState } from 'react';
import Tiles from '../../components/reusable/Tiles';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const Plbn = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [plbn, setPlbn] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/plbn_aktif`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                const processedData = response.data.plbn.data.map(item => ({
                    plbn: item.plbn,
                    displayName: item.plbn.replace(/^PLBN\s+/, ''), // Keep the name for display purposes
                    url: `/plbn/${item.plbn}`, // Use the original PLBN name in the URL
                }));

                setPlbn(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token, baseURL]);

    const handleTileClick = (item) => {
        navigate(item.url, { state: { plbnData: item.plbn } });
    };

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">PLBN</div>
            <div className="mt-8 font-mp-bold">
                <div className="grid grid-cols-2 gap-4 laptop:grid laptop:grid-cols-5">
                    {plbn.map((item, id) => (
                        <div
                            key={id}
                            className="cursor-pointer" // Add pointer cursor for tiles
                            onClick={() => handleTileClick(item)} // Pass the item object
                        >
                            <Tiles>
                                <div className="mt-4">{item.displayName}</div>
                            </Tiles>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Plbn;