import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const InputUserForm = () => {
  const baseURL = useContext(ApiContext);
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    password: '',
    satker: '',
    instansi: '',
    access_level: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [satkerOptions, setSatkerOptions] = useState([]);
  const [instansiOptions, setInstansiOptions] = useState([]);

  const { auth } = useContext(AuthContext);
  const token = auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [satkerResponse, instansiResponse] = await Promise.all([
          axios.get(`${baseURL}/api/dropdown_polda`, {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          axios.get(`${baseURL}/api/dropdown_polres_plbn`, {
            headers: { 'Authorization': `Bearer ${token}` }
          })
        ]);

        setSatkerOptions([
          { value: '', label: '-- Pilih Opsi --' },
          ...satkerResponse.data.polda.map(polda => ({
            value: polda.polda,
            label: polda.polda,
          })),
        ]);

        setInstansiOptions([
          { value: '', label: '-- Pilih Opsi --' },
          ...instansiResponse.data.satuan.map(satuan => ({
            value: satuan.instansi,
            label: satuan.instansi,
          })),
        ]);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchOptions();
  }, [token, baseURL]);

  useEffect(() => {
    if (isEditMode) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/user/${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          const user = response.data.user;
          setFormData({
            username: user.username || '',
            name: user.name || '',
            email: user.email || '',
            password: '', // Do not pre-fill password
            satker: user.satker || '',
            instansi: user.instansi || '',
            access_level: user.access_level || '',
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    }
  }, [id, isEditMode, token, baseURL]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = isEditMode ? `${baseURL}/api/update_user/${id}` : `${baseURL}/api/register`;

    try {
      const response = await axios.post(apiEndpoint, formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('User saved successfully:', response.data);
      navigate('/admin/adminuser');
    } catch (error) {
      console.error('Error saving user:', error.response ? error.response.data : error.message);
    }
  };

  const handleCancel = () => {
    navigate('/admin/adminuser');
  };

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
            <div className="text-lg font-bold">{isEditMode ? 'Edit User' : 'Input User'}</div>
            <div className="flex flex-col gap-6 px-4">
              <FormInput label="Username" type="text" name="username" value={formData.username} handleChange={handleChange} />
              <FormInput label="Fullname" type="text" name="name" value={formData.name} handleChange={handleChange} />
              <FormInput label="Email" type="email" name="email" value={formData.email} handleChange={handleChange} />
              <PasswordInput showPassword={showPassword} togglePasswordVisibility={togglePasswordVisibility} value={formData.password} handleChange={handleChange} />
              <FormSelect 
                label="Access Level" 
                name="access_level" 
                value={formData.access_level} 
                handleChange={handleChange} 
                options={[
                  { value: '', label: '-- Pilih Opsi --' },
                  ...Array.from({ length: 4 },
                  (_, i) => ({ value: i.toString(), label: i.toString() }))]} 
              />
              <FormSelect label="POLDA" name="satker" value={formData.satker} handleChange={handleChange} options={satkerOptions} />
              <FormSelect label="Satuan" name="instansi" value={formData.instansi} handleChange={handleChange} options={instansiOptions} />
            </div>
            <div className="flex flex-row justify-end gap-3 mt-8">
              <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FormInput = ({ label, type, name, value, handleChange }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <input
        type={type}
        name={name}
        id={name}
        autoComplete="given-name"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      />
    </div>
  </div>
);

const PasswordInput = ({ showPassword, togglePasswordVisibility, value, handleChange }) => (
  <div className="flex flex-col relative">
    <label htmlFor="password" className="block text-base leading-6 text-gray">Password</label>
    <div className="mt-2 relative">
      <input
        type={showPassword ? 'text' : 'password'}
        name="password"
        id="password"
        autoComplete="new-password"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      />
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        className="text-blue h-5 w-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
        onClick={togglePasswordVisibility}
      />
    </div>
  </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <select
        id={name}
        name={name}
        autoComplete="given-name"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

export default InputUserForm;

