import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const PlbnDetail = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const location = useLocation();

    // Access the passed data
    const { plbnData } = location.state || {};

    const [personelData, setPersonelData] = useState([]);
    const [laporanData, setLaporanData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [isPersonel, setIsPersonel] = useState(true);
    const [chartData, setChartData] = useState({
        laporanBiasa: [],
        laporanKhusus: [],
        laporanTotal: 0,
        biasaTotal: 0,
        khususTotal: 0,
    });
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            let endpoint = `/api/plbn_details/${plbnData}?page=${page}`;

            const response = await axios.get(`${baseURL}${endpoint}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            setPersonelData(response.data.personel || []);
            setChartData({
                laporanBiasa: response.data.chart_biasa || [],
                laporanKhusus: response.data.chart_khusus || [],
                laporanTotal: response.data.laporan_total || 0,
                biasaTotal: response.data.laporan_biasa || 0,
                khususTotal: response.data.laporan_khusus || 0,
            });

            const laporanData = response.data.list_laporan;
            setLaporanData(laporanData.data);
            setPaginationLinks(laporanData.links);
            setCurrentPage(laporanData.current_page);
            setTotalPages(laporanData.last_page);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, token, plbnData]);

    useEffect(() => {
        fetchData(1);
    }, [fetchData]);

    const handleEditClick = (id, type) => {
        if (type === 'personel') {
            navigate(`/admin/editpersonel/${id}`);
        } else if (type === 'laporan'){
            navigate(`/laporan/editlaporan/${id}`)
        }
    };

    const handleDeleteClick = (id, type) => {
        setSelectedItemId(id);
        setIsPersonel(type === 'personel');
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            if (isPersonel) {
                await axios.delete(`${baseURL}/api/delete_personel/${selectedItemId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            } else {
                await axios.delete(`${baseURL}/api/delete_laporan/${selectedItemId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            }
            fetchData(currentPage);
        } catch (error) {
            console.error('Error deleting item:', error);
        }
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            fetchData(page);
        }
    };

    const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
                <div className="flex flex-row justify-between">
                    <button
                        onClick={handleConfirmDelete}
                        className="bg-green text-white px-4 py-2 rounded-lg"
                    >
                        Ya
                    </button>
                    <button
                        onClick={handleCancelDelete}
                        className="bg-red text-white px-4 py-2 rounded-lg"
                    >
                        Tidak
                    </button>
                </div>
            </div>
        </div>
    );

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    const toUpperCase = (str) => {
        return str ? str.toUpperCase() : '';
    };

    return (
        <div className="">
            <div className="font-mp-bold text-xl text-gray-light">{plbnData}</div>
            <div className="mt-8">
                <div className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                        <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                            <div className="text-lg font-mp-bold justify-start">
                                Laporan
                            </div>
                            <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Biasa</div>
                                    <LaporanBiasa
                                        laporanBiasa={chartData.laporanBiasa}
                                        laporanBiasaTotal={chartData.biasaTotal}
                                    />
                                </div>
                                <div className="flex flex-col items-center text-center font-mp-bold">
                                    <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                    <div className="text-xl">{chartData.laporanTotal}</div>
                                </div>
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Khusus</div>
                                    <LaporanKhusus
                                        laporanKhusus={chartData.laporanKhusus}
                                        laporanKhususTotal={chartData.khususTotal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white laptop:h-full desktop:h-full px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">Kontak</div>
                        </div>
                        <div className="mt-6 text-sm">
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    NO
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    JABATAN
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    NAMA
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    KONTAK
                                                </th>
                                                {
                                                    auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                        <>
                                                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                        </>
                                                    ) : null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
                                            {personelData.map((personel, index) => (
                                                <tr key={personel.id}>
                                                    <td className="px-5 py-2 whitespace-normal">{index + 1}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{personel.jabatan}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{personel.nama}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{personel.kontak}</td>
                                                    <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                    {
                                                        auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                            <>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                                <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleEditClick(personel.id, 'personel')}
                                                                    />
                                                                </button>
                                                                <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleDeleteClick(personel.id, 'personel')}
                                                                    />
                                                                </button>
                                                                </td>
                                                            </>
                                                        ) : null
                                                    }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white laptop:h-full desktop:h-full px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">Laporan</div>
                        </div>
                        <div className="mt-6 text-sm">
                        {loading ? (
                            <div className="text-center py-4">Loading data...</div>
                        ) : (
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    JUDUL LAPORAN
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    WAKTU DIBUAT
                                                </th>
                                                {auth?.user?.access_level >= 0 && auth?.user?.access_level <= 2 && (
                                                    <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
                                            {laporanData.map((laporan) => (
                                                <tr key={laporan.id}>
                                                    <td className="px-5 py-4 whitespace-normal overflow-hidden">
                                                        <Link to={`/laporan/${laporan.id}`}>{toUpperCase(laporan.judulLaporan)}</Link>
                                                    </td>
                                                    <td className="px-5 py-2 whitespace-normal">{new Date(laporan.created_at).toLocaleDateString('en-GB')}</td>
                                                    {auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 && (
                                                        <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                            <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    className="text-white h-4 w-4"
                                                                    onClick={() => handleEditClick(laporan.id, 'laporan')}
                                                                />
                                                            </button>
                                                            <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    className="text-white h-4 w-4"
                                                                    onClick={() => handleDeleteClick(laporan.id, 'laporan')}
                                                                />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                </div>
                {renderPagination()}
            </div>
                </div>
            </div>

            {/* Modal for delete confirmation */}
            {showModal && (
                <DeleteConfirmationModal
                handleConfirmDelete={handleConfirmDelete}
                handleCancelDelete={handleCancelDelete}
            />
            )}
        </div>
    );
};

const LaporanBiasa = ({ laporanBiasa, laporanBiasaTotal }) => {
    const defaultData = {
        labels: ['Patroli', 'Pengamanan', 'Sosialisasi', 'Sinergitas'],
        series: [0, 0, 0, 0],
    };

    const mapLaporanBiasa = (data) => ({
        'Patroli': data.patroli || 0,
        'Pengamanan': data.pengamanan || 0,
        'Sosialisasi': data.sosialisasi || 0,
        'Sinergitas': data.sinergitas || 0,
    });

    const mappedData = laporanBiasa ? mapLaporanBiasa(laporanBiasa) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBiasaChart laporanBiasa={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBiasaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBiasaChart = ({ laporanBiasa }) => {
    const { labels = [], series = [] } = laporanBiasa || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanKhusus = ({ laporanKhusus, laporanKhususTotal }) => {
    const defaultData = {
        labels: ['Penyelundupan Orang', 'Penyelundupan Sembako', 'Penyelundupan Satwa', 'Penyelundupan Hasil Bumi',
            'Penyelundupan Senjata/Bahan Peledak', 'Narkoba', 'Pencurian Ikan/Illegal Fishing', 'Pelintas Batas Ilegal', 'Terorisme', 'Lainnya'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };

    const mapLaporanKhusus = (data) => ({
        'Penyelundupan Orang': data.orang || 0,
        'Penyelundupan Sembako': data.sembako || 0,
        'Penyelundupan Satwa': data.satwa || 0,
        'Penyelundupan Hasil Bumi': data.bumi || 0,
        'Penyelundupan Senjata/Bahan Peledak': data.senjata || 0,
        'Narkoba': data.narkoba || 0,
        'Pencurian Ikan/Illegal Fishing': data.ikan || 0,
        'Pelintas Batas Ilegal': data.batas || 0,
        'Terorisme': data.terorisme || 0,
        'Lainnya': data.lainnya || 0,
    });

    const mappedData = laporanKhusus ? mapLaporanKhusus(laporanKhusus) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanKhususChart laporanKhusus={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanKhususTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanKhususChart = ({ laporanKhusus }) => {
    const { labels = [], series = [] } = laporanKhusus || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6', '#F97316', '#14B8A6', '#6B7280', '#6366F1', '#EC4899'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

export default PlbnDetail;
