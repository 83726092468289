import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const PersonelPlbnForm = () => {
    const baseURL = useContext(ApiContext);
    const [plbnOptions, setPlbnOptions] = useState([]);
    const [formData, setFormData] = useState({
        plbn: '',
        jabatan: '',
        nama: '',
        kontak: ''
    });

    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/plbn_all`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                // Extract the data array from the response
                const plbnData = response.data.plbn.data || [];

                // Create options for the dropdown
                const options = [
                    { value: '', label: '-- Pilih Opsi --' },
                    ...plbnData.map(plbn => ({
                        value: plbn.plbn,
                        label: `${plbn.plbn}`, // Modify label as needed
                    })),
                ];

                setPlbnOptions(options);

            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        };

        fetchOptions();
    }, [token, baseURL]);

    useEffect(() => {
        if (isEditMode) {
            const fetchPersonelData = async () => {
                try {
                    const response = await axios.get(`${baseURL}/api/personel_plbn/${id}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setFormData({
                        plbn: response.data.personel.plbn || '',
                        jabatan: response.data.personel.jabatan || '',
                        nama: response.data.personel.nama || '',
                        kontak: response.data.personel.kontak || ''
                    });
                } catch (error) {
                    console.error('Error fetching personel data:', error);
                }
            };

            fetchPersonelData();
        }
    }, [id, isEditMode, token, baseURL]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEditMode ? `${baseURL}/api/update_personel_plbn/${id}` : `${baseURL}/api/store_personel_plbn`;

        try {
            const response = await axios.post(apiEndpoint, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Personel PLBN saved successfully:', response.data);
            navigate(-1);
        } catch (error) {
            console.error('Error saving personel PLBN:', error.response ? error.response.data : error.message);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <div className="mt-8">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="text-lg font-bold">{isEditMode ? 'Edit Personel PLBN' : 'Input Personel PLBN'}</div>
                        <div className="flex flex-col gap-6 px-4">
                            <FormSelect label="PLBN" name="plbn" value={formData.plbn} handleChange={handleChange} options={plbnOptions} />
                            <FormInput label="Jabatan" type="text" name="jabatan" value={formData.jabatan} handleChange={handleChange} />
                            <FormInput label="Nama" type="text" name="nama" value={formData.nama} handleChange={handleChange} />
                            <FormInput label="Kontak" type="text" name="kontak" value={formData.kontak} handleChange={handleChange} />
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Cancel
                            </button>
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const FormInput = ({ label, type, name, value, handleChange }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <input
                type={type}
                name={name}
                id={name}
                autoComplete="off"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                value={value}
                onChange={handleChange}
            />
        </div>
    </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
    <div className="flex flex-col">
      <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
      <div className="mt-2">
        <select
          id={name}
          name={name}
          autoComplete="off"
          className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
          value={value}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

export default PersonelPlbnForm;