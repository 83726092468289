import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="relative overflow-hidden w-full bg-blue bg-center bg-cover">
            <div className="flex flex-row justify-center h-screen w-full px-8 py-6">
                <div className="flex flex-col justify-between text-center">
                    <div className="flex flex-col">
                        <div className="font-mp-bold text-2xl text-red">IMBAS</div>
                        <div className="text-xl text-white">Informasi Manajemen Perbatasan</div>
                    </div>
                    
                    <div className="flex flex-col h-1/2 justify-between">
                        <div className="flex flex-col">
                            <div className="font-mp-bold text-3xl text-white">Unauthorized</div>
                            <div className="text-lg text-white">You do not have access to the requested page</div>
                        </div>
                        
                        <button onClick={goBack} className="text-lg text-red">Back</button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Unauthorized