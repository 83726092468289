import React from 'react'
import { Link } from 'react-router-dom';

export default function Tiles({link, children}) {
    return (
        <Link to={link}>
            <div className="flex flex-col min-h-auto p-4 justify-center items-center text-center text-base  bg-white border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                {children}
            </div>
        </Link>
    )
}
