import React, { useCallback, useContext, useState } from 'react'
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import axios from '../../api/axios';

const RekapPolda = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        start_date: '',
        end_date: '',
    });

    const [errors, setErrors] = useState({});
    const [chartData, setChartData] = useState({
        laporanBiasa: [],
        laporanKhusus: [],
        laporanTotal: 0,
        biasaTotal: 0,
        khususTotal: 0,
        poldaPerbatasan: {},
    });

    const [dataSubmitted, setDataSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear the error message for the field being changed
    };

    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];
    };

    const fetchData = useCallback(async () => {
        try {
            const { start_date, end_date } = formData;
            if (!start_date || !end_date) return;

            // Format dates
            const formattedStartDate = formatDate(start_date);
            const formattedEndDate = formatDate(end_date);

            const response = await axios.get(
                `${baseURL}/api/rekap_polda/${formattedStartDate}&${formattedEndDate}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setChartData({
                laporanBiasa: response.data.laporan_biasa || [],
                laporanKhusus: response.data.laporan_khusus || [],
                laporanTotal: response.data.total_laporan_polda || 0,
                biasaTotal: response.data.kategori_laporan?.biasa || 0,
                khususTotal: response.data.kategori_laporan?.khusus || 0,
                poldaPerbatasan: response.data.polda_perbatasan || {},
            });

            setLoading(false);
            setDataSubmitted(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }, [baseURL, formData, token]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.start_date) {
            newErrors.start_date = 'Start date is required';
        }
        if (!formData.end_date) {
            newErrors.end_date = 'End date is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetchData();
        }
    };

    

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Rekap POLDA</div>
            <div className="mt-8">
            <form onSubmit={handleSubmit} className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="flex flex-col gap-6 px-4">
                            <div className="text-lg font-mp-bold justify-start">
                                Range Tanggal
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="start_date" className="block text-base leading-6 text-gray">Dari</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        autoComplete="given-name"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-box focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.start_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.start_date && <span className="text-red text-sm">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="end_date" className="block text-base leading-6 text-gray">Sampai</label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        autoComplete="given-name"
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors.end_date ? 'ring-red-500' : ''}`}
                                    />
                                    {errors.end_date && <span className="text-red text-sm">{errors.end_date}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">Submit</button>
                        </div>
                    </div>
                    {dataSubmitted && (
                        loading ? (
                            <div className="overflow-auto rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                    Loading...
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-auto rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                    <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                        <div className="text-lg font-mp-bold justify-start">
                                            POLDA Perbatasan
                                        </div>
                                        <PoldaPerbatasanChart poldaPerbatasan={chartData.poldaPerbatasan} />
                                    </div>
                                </div>
                                <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                                    <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                                        <div className="text-lg font-mp-bold justify-start">
                                            Laporan
                                        </div>
                                        <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Biasa</div>
                                                <LaporanBiasa
                                                    laporanBiasa={chartData.laporanBiasa}
                                                    laporanBiasaTotal={chartData.biasaTotal}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center text-center font-mp-bold">
                                                <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                                <div className="text-xl">{chartData.laporanTotal}</div>
                                            </div>
                                            <div className="flex flex-col items-center text-base">
                                                <div className="font-mp-bold">Khusus</div>
                                                <LaporanKhusus
                                                    laporanKhusus={chartData.laporanKhusus}
                                                    laporanKhususTotal={chartData.khususTotal}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </form>
            </div>
        </section>
    );
};

const PoldaPerbatasanChart = ({ poldaPerbatasan }) => {
    const defaultData = {
        labels: [
            'Aceh', 'Sumatera Utara', 'Sumatera Barat', 'Riau', 'Kepulauan Riau', 'Bengkulu', 
            'Lampung', 'Banten', 'Jawa Barat', 'Jawa Tengah', 'Jawa Timur', 'Kalimantan Timur', 
            'Kalimantan Barat', 'Kalimantan Utara', 'Sulawesi Utara', 'Sulawesi Tengah', 'Bali', 
            'NTB', 'NTT', 'Maluku', 'Maluku Utara', 'Papua', 'Papua Barat'
        ],
        series: Array(24).fill(0)
    };

    const mapPoldaPerbatasan = (data) => ({
        'Aceh': data.aceh || 0,
        'Sumatera Utara': data.sumatera_utara || 0,
        'Sumatera Barat': data.sumatera_barat || 0,
        'Riau': data.riau || 0,
        'Kepulauan Riau': data.kepulauan_riau || 0,
        'Bengkulu': data.bengkulu || 0,
        'Lampung': data.lampung || 0,
        'Banten': data.banten || 0,
        'Jawa Barat': data.jawa_barat || 0,
        'Jawa Tengah': data.jawa_tengah || 0,
        'Jawa Timur': data.jawa_timur || 0,
        'Kalimantan Timur': data.kalimantan_timur || 0,
        'Kalimantan Barat': data.kalimantan_barat || 0,
        'Kalimantan Utara': data.kalimantan_utara || 0,
        'Sulawesi Utara': data.sulawesi_utara || 0,
        'Sulawesi Tengah': data.sulawesi_tengah || 0,
        'Bali': data.bali || 0,
        'NTB': data.ntb || 0,
        'NTT': data.ntt || 0,
        'Maluku': data.maluku || 0,
        'Maluku Utara': data.maluku_utara || 0,
        'Papua': data.papua || 0,
        'Papua Barat': data.papua_barat || 0
    });

    const mappedData = poldaPerbatasan ? mapPoldaPerbatasan(poldaPerbatasan) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex p-5">
            <BarChart labels={labels} series={series} />
        </div>
    );
};

const BarChart = ({ labels = [], series = [] }) => {
    const options = {
        labels: labels,
        colors: ['#228159', '#DC2626'],
        chart: {
            type: 'bar'
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        stroke: {
            width: 3
        },
        markers: {
            size: 3
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        // yaxis: {
        //     min: 0,           // Minimum value on the y-axis
        //     max: 100,         // Maximum value on the y-axis
        //     tickAmount: 5,    // Number of ticks to show
        //     labels: {
        //         formatter: (value) => `${value}`, // Formatting labels
        //     }
        // },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    return (
        <div className="h-[20%] w-full">
            <ApexCharts options={options} series={series} type="bar" />
        </div>
    );
};


const LaporanBiasa = ({ laporanBiasa, laporanBiasaTotal }) => {
    const defaultData = {
        labels: ['Patroli', 'Pengamanan', 'Sosialisasi', 'Sinergitas'],
        series: [0, 0, 0, 0],
    };

    const mapLaporanBiasa = (data) => ({
        'Patroli': data.patroli || 0,
        'Pengamanan': data.pengamanan || 0,
        'Sosialisasi': data.sosialisasi || 0,
        'Sinergitas': data.sinergitas || 0,
    });

    const mappedData = laporanBiasa ? mapLaporanBiasa(laporanBiasa) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBiasaChart laporanBiasa={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBiasaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBiasaChart = ({ laporanBiasa }) => {
    const { labels = [], series = [] } = laporanBiasa || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanKhusus = ({ laporanKhusus, laporanKhususTotal }) => {
    const defaultData = {
        labels: ['Penyelundupan Orang', 'Penyelundupan Sembako', 'Penyelundupan Satwa', 'Penyelundupan Hasil Bumi',
            'Penyelundupan Senjata/Bahan Peledak', 'Narkoba', 'Pencurian Ikan/Illegal Fishing', 'Pelintas Batas Ilegal', 'Terorisme', 'Lainnya'],
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };

    const mapLaporanKhusus = (data) => ({
        'Penyelundupan Orang': data.orang || 0,
        'Penyelundupan Sembako': data.sembako || 0,
        'Penyelundupan Satwa': data.satwa || 0,
        'Penyelundupan Hasil Bumi': data.bumi || 0,
        'Penyelundupan Senjata/Bahan Peledak': data.senjata || 0,
        'Narkoba': data.narkoba || 0,
        'Pencurian Ikan/Illegal Fishing': data.ikan || 0,
        'Pelintas Batas Ilegal': data.batas || 0,
        'Terorisme': data.terorisme || 0,
        'Lainnya': data.lainnya || 0,
    });

    const mappedData = laporanKhusus ? mapLaporanKhusus(laporanKhusus) : defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanKhususChart laporanKhusus={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanKhususTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanKhususChart = ({ laporanKhusus }) => {
    const { labels = [], series = [] } = laporanKhusus || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04', '#1f2937', '#8B5CF6', '#F97316', '#14B8A6', '#6B7280', '#6366F1', '#EC4899'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    // Flatten the series data from the array of objects to just an array of numbers
    const chartSeries = series.length > 0 ? series[0].data : [];

    return (
        <div className="w-full">
            <ApexCharts options={options} series={chartSeries} type="donut" loading="lazy" />
        </div>
    );
};

export default RekapPolda
