import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';
import { useNavigate, useParams } from 'react-router-dom';

const SatkerDetail = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const { satkerName } = useParams();

    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);

    const fetchData = useCallback((page) => {
        axios.get(`${baseURL}/api/${satkerName}?page=${page}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            const { data, links = [], last_page, current_page } = response.data[satkerName];
            setData(data || []); // Ensure data is an array
            setPaginationLinks(links || []);
            setCurrentPage(current_page || 1);
            setTotalPages(last_page || 1);
        })
        .catch(error => {
            console.error('There was an error fetching the data!', error);
        });
    }, [token, baseURL, satkerName]);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, fetchData]);

    const deleteData = useCallback((id) => {
        axios.delete(`${baseURL}/api/delete_satker/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(() => {
            fetchData(currentPage); // Refresh data after deletion
        })
        .catch(error => {
            console.error('There was an error deleting the data!', error);
        });
    }, [token, baseURL, currentPage, fetchData]);

    const handleEditClick = (id) => {
        navigate(`/satker/editSatker/${id}`);
    };

    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deleteData(selectedId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    // Extract the first item's 'kementrian' field or default to 'No Data'
    const satker = data.length > 0 ? data[0].satker : ' ';

    return (
        <div className="h-screen">
            <div className="font-mp-bold text-xl text-gray-light">{satker}</div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="mt-6 text-base">
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    SATKER
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    BAGIAN
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    NAMA
                                                </th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">
                                                    KONTAK
                                                </th>
                                                {
                                                    auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                        <>
                                                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                        </>
                                                    ) : null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
                                            {data.map((item) => (
                                                <tr key={item.id}>
                                                    <td className="px-5 py-2 whitespace-normal">{item.satker}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{item.bagian}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{item.nama}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{item.kontak}</td>
                                                    <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                    {
                                                        auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                            <>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                                <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleEditClick(item.id, 'personel')}
                                                                    />
                                                                </button>
                                                                <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleDeleteClick(item.id, 'personel')}
                                                                    />
                                                                </button>
                                                                </td>
                                                            </>
                                                        ) : null
                                                    }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {renderPagination()}
                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
            <div className="flex flex-row justify-between">
                <button
                    onClick={handleConfirmDelete}
                    className="bg-green text-white px-4 py-2 rounded-lg"
                >
                    Ya
                </button>
                <button
                    onClick={handleCancelDelete}
                    className="bg-red text-white px-4 py-2 rounded-lg"
                >
                    Tidak
                </button>
            </div>
        </div>
    </div>
);

export default SatkerDetail;
