import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const TpiForm = ({ type, title }) => {
  const baseURL = useContext(ApiContext);
  const [formData, setFormData] = useState({
    provinsi: '',
    jenis: type,
    tpi: '',
    wilkum: '',
    nama: '',
    kontak: ''
  });

  const { auth } = useContext(AuthContext);
  const token = auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  useEffect(() => {
    if (isEditMode) {
      const fetchTpiData = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/tpi/${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          // Destructure the tpi object from the response
          const { tpi } = response.data;
          setFormData({
            provinsi: tpi.provinsi || '',
            jenis: tpi.jenis || type, // Use the jenis from the response, fallback to prop
            tpi: tpi.tpi || '',
            wilkum: tpi.wilkum || '',
            nama: tpi.nama || '',
            kontak: tpi.kontak || '',
          });
        } catch (error) {
          console.error(`Error fetching ${type} data:`, error);
        }
      };

      fetchTpiData();
    }
  }, [id, isEditMode, token, baseURL, type]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = isEditMode ? `${baseURL}/api/update_tpi/${id}` : `${baseURL}/api/store_tpi`;

    try {
      const response = await axios.post(apiEndpoint, formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log(`${type} saved successfully:`, response.data);
      navigate(-1);
    } catch (error) {
      console.error(`Error saving ${type}:`, error.response ? error.response.data : error.message);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
            <div className="text-lg font-bold">{isEditMode ? `Edit ${title}` : `Input ${title}`}</div>
            <div className="flex flex-col gap-6 px-4">
              <FormInput label="Provinsi" type="text" name="provinsi" value={formData.provinsi} handleChange={handleChange} />
              <FormInput label="Jenis" type="text" name="jenis" value={formData.jenis} handleChange={handleChange} readonly="readonly"/>
              <FormInput label="TPI" type="text" name="tpi" value={formData.tpi} handleChange={handleChange} />
              <FormInput label="Wilkum" type="text" name="wilkum" value={formData.wilkum} handleChange={handleChange} />
              <FormInput label="Nama" type="text" name="nama" value={formData.nama} handleChange={handleChange} />
              <FormInput label="Kontak" type="text" name="kontak" value={formData.kontak} handleChange={handleChange} />
            </div>
            <div className="flex flex-row justify-end gap-3 mt-8">
              <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FormInput = ({ label, type, name, value, handleChange, readonly }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <input
        type={type}
        name={name}
        id={name}
        autoComplete="given-name"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
        readonly={readonly}
      />
    </div>
  </div>
);

export default TpiForm;
